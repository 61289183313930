import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./systemDetails.scss";
import CsdDetails from "./CsdDetails/csdDetails";
import MessagesDataTable from "./Messages/messagesDataTable";
import EquipmentDataTable from "./Equipment/equipmentDataTable";
import UserDataTable from "./User/userDataTable";
import { CustomerSvg, TruckSvg, WarningSvg } from "@cd/sdds-common-components-react";
import RecordedImpacts from "./RecordedImpacts";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getOverviewDetails, getOverviewDetailsProdEntity, getOverviewDetailsUser,clearOverviewDetails } from "../../../redux/store/overviewDetailsStore";
import { loadCsdAttributes } from "../../../redux/store/CsdAttributesStore";

const SystemDetails = () => {
  const tabsRef = React.createRef<HTMLTdsFolderTabsElement>();
  const { t } = useTranslation();
  const { systemName, csdId } = useAppSelector<CsdIdSelectionType>(state => state.CsdIdSelectionStore);
  const {
    equipmentImpactOverview,
    userImpactOverview,
    csdIdTime,
    isLoading,
    paginationToken,
    paginationTokenProductEntity,
    paginationTokenUser,
    isReset} = useAppSelector<OverviewDetailsStateType>(state => state.OverviewDetailsStore);
  const { startTime, endTime } = useAppSelector<SearchInputType>(state => state.selectedDateStore);

  const dispatch = useAppDispatch();

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [selectedCsd, setSelectedCsd] = useState<CsdIdTimeType>();
  const [selectedImpacts, setSelectedImpacts] = useState<string[]>([]);
  const [impactViewClosed, setImpactViewClosed] = useState<boolean>(true);
  const [detailsViewClosed, setDetailsViewClosed] = useState<boolean>(true);
  const [activeRowIndex, setActiveRowIndex] = useState(-1);
  const [activeEqRowIndex, setActiveEqRowIndex] = useState(-1);
  const [activeUserRowIndex, setActiveUserRowIndex] = useState(-1);
  const [loadMoreMsgs, setLoadMoreMsgs] = useState<boolean>(false);
  const [loadMoreEquipment, setLoadMoreEquipment] = useState<boolean>(false);
  const [loadMoreUsers, setLoadMoreUsers] = useState<boolean>(false);

  useEffect(() => {
    if (tabsRef.current) {
      tabsRef.current.addEventListener("tdsChange", (event: any) => {
        if (event && event.detail) {
          setSelectedTabIndex(event.detail.selectedTabIndex);
        }
      });
    }
  }, [tabsRef.current]);

  useEffect(() => {
    setImpactViewClosed(true);
    setDetailsViewClosed(true);
  }, [selectedTabIndex]);

  useEffect(() => {
    setActiveRowIndex(-1);
    setActiveEqRowIndex(-1);
    setActiveUserRowIndex(-1);
    setSelectedCsd(undefined)
  }, [csdIdTime, equipmentImpactOverview, userImpactOverview, selectedTabIndex]);


  useEffect(() => {  
    //load all CSD messages, equipment and user impacts when system selection changes
      dispatch(clearOverviewDetails());      
  }, [systemName, csdId]);

  
  useEffect(() => {  
    handleLoadMsgs();
    handleLoadEq(); 
    handleLoadUsers();     
  }, [isReset]);
  

  useEffect(() => {
    //reveal load more button in messages table
    if (paginationToken === '{}') setLoadMoreMsgs(false);
    else setLoadMoreMsgs(true);
  }, [paginationToken])

  useEffect(() => {
    //reveal load more button in equipment table   
    if (paginationTokenProductEntity === '{}') setLoadMoreEquipment(false);
    else setLoadMoreEquipment(true);
  }, [paginationTokenProductEntity])


  useEffect(()=>{
     //reveal load more button in user table
     if (paginationTokenUser === '{}') setLoadMoreUsers(false);
     else setLoadMoreUsers(true);
  },[paginationTokenUser])

  const handleLoadMsgs = (action?: string) => {
    if (systemName !== undefined && csdId !== undefined && startTime !== '' && endTime !== '') {
      var request: OverviewDetailsType = {
        startTime: startTime,
        endTime: endTime,
        csdHeaders: [{ systemName: systemName, id: csdId }],
        paginationToken: paginationToken
      }
      if (action)
        dispatch(getOverviewDetails(request, action));
      else
        dispatch(getOverviewDetails(request));
    }
  }
  const handleLoadEq = (action?: string) => {
    if (systemName !== undefined && csdId !== undefined && startTime !== '' && endTime !== '') {     
      var request: OverviewDetailsType = {
        startTime: startTime,
        endTime: endTime,
        csdHeaders: [{ systemName: systemName, id: csdId }],
        paginationToken: paginationTokenProductEntity
      }
      if (action)
        dispatch(getOverviewDetailsProdEntity(request, action));
      else
        dispatch(getOverviewDetailsProdEntity(request));
    }
  }
  const handleLoadUsers = (action?: string) => {
    if (systemName !== undefined && csdId !== undefined && startTime !== '' && endTime !== '') {      
      var request: OverviewDetailsType = {
        startTime: startTime,
        endTime: endTime,
        csdHeaders: [{ systemName: systemName, id: csdId }],
        paginationToken: paginationTokenUser
      }
      if (action)
        dispatch(getOverviewDetailsUser(request, action));
      else
        dispatch(getOverviewDetailsUser(request));
    }
  }

  const onSelectCsd = (csd: CsdIdTimeType) => {
    setSelectedCsd(csd);
    dispatch(loadCsdAttributes(csd.id));
    setDetailsViewClosed(false);
  };

  const onSelectedImpacts = (csd: CsdImpactType) => {
    setSelectedImpacts([]);
    if (csd.timestamps.length > 1) {
      setSelectedImpacts(csd.timestamps);
      setImpactViewClosed(false);
    }
    else
      setImpactViewClosed(true);
  };

  const onDetailsClose = () => {
    setDetailsViewClosed(true);
  };

  const onImpactsClose = () => {
    setImpactViewClosed(true);
    setSelectedImpacts([]);
  };

  return (
    <>
      <div className="selected-system-details">
        <div className="panel-wrapper">
          <div className="grid-panel">
            <div className="sdds-headline-05">
              {systemName}
            </div>
            <tds-folder-tabs ref={tabsRef}>
              <tds-folder-tab>
                <button>
                  <WarningSvg /> {t("TS_core:Messages")}
                </button>
              </tds-folder-tab>
              <tds-folder-tab>
                <button>
                  <TruckSvg /> {t("TS_core:Equipment")}
                </button>
              </tds-folder-tab>
              <tds-folder-tab>
                <button>
                  <CustomerSvg /> {t("TS_core:User")}
                </button>
              </tds-folder-tab>
            </tds-folder-tabs>
            {selectedTabIndex === 0 && (
              <div className="tab-container">               
                  {<MessagesDataTable
                    tableData={csdIdTime}
                    isLoading={isLoading}
                    onSelectCsd={onSelectCsd}
                    activeRowIndex={activeRowIndex}
                    setActiveRowIndex={setActiveRowIndex}
                  />}  
                  {loadMoreMsgs &&  <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-primary  sdds-u-float-right load-more-btn" onClick={()=>handleLoadMsgs('append')}>load more messages...</button>}             
              </div>
            )}
            {selectedTabIndex === 1 && (
              <div className="tab-container">               
                  <EquipmentDataTable
                    tableData={equipmentImpactOverview}
                    title={""}
                    isLoading={false}
                    onSelectedImpacts={onSelectedImpacts}
                    activeRowIndex={activeEqRowIndex}
                    setActiveRowIndex={setActiveEqRowIndex}
                  />   
                 {loadMoreEquipment &&  <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-primary  sdds-u-float-right load-more-btn" onClick={()=>handleLoadEq('append')}>load more equipment...</button>}          
              </div>
            )}
            {selectedTabIndex === 2 && (
              <div className="tab-container">                
                  <UserDataTable
                    tableData={userImpactOverview}
                    title={""}
                    isLoading={false}
                    onSelectedImpacts={onSelectedImpacts}
                    activeRowIndex={activeUserRowIndex}
                    setActiveRowIndex={setActiveUserRowIndex}
                  />    
                  {loadMoreUsers &&  <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-primary  sdds-u-float-right load-more-btn" onClick={()=>handleLoadUsers('append')}>load more users...</button>}              
              </div>
            )}
          </div>
        </div>
      </div>

      {selectedCsd && !detailsViewClosed && (
        <div className="detailsAndImpact">
          <div className="csd-details-panel">
            <CsdDetails onClose={onDetailsClose} csd={selectedCsd} />{" "}
          </div>
        </div>
      )}

      {selectedImpacts && !impactViewClosed && (
        <div className="detailsAndImpact">
          <div className="recorded-impacts-panel">
            <RecordedImpacts onClose={onImpactsClose} impactList={selectedImpacts} />{" "}
          </div>
        </div>
      )}

    </>
  );
};


export default SystemDetails;
