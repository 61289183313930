import React, { ChangeEvent, memo } from "react"

interface sddsCheckboxPropType {
  label?: string,
  id: string;
  disabled?: boolean
  checked?: boolean
  value: string
  onClick?: (isChecked: boolean) => void
}

const SddsCheckbox = ({ id, label, disabled, checked, value, onClick }: sddsCheckboxPropType) => {

  const onCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    onClick && onClick(e.target.checked)
  }

  return <>
    <div className="sdds-checkbox-item">
      <input className="sdds-form-input" type="checkbox" checked={checked} disabled={disabled} name={id} id={id} value={value} onChange={onCheckChange} />
      <label className="sdds-form-label" htmlFor={id}>
        {label}
      </label>
    </div>
  </>
}
export default memo(SddsCheckbox)
