import { createSlice } from '@reduxjs/toolkit'
import { getCsdDefinitions } from '../../adapters/CsdFunctionsAdapter';
import { createDefinition, getAllFunctions, updateDefinition } from '../../adapters/DefinitionAdapter';

const getInitialState = (definitions: Array<Definitionto> | null): DefinitionsState => {
    const viewDefinitionsInitialState: DefinitionsState = {
        isLoading: false,
        hasError: false,
        errorMessage: "",
        allDefinitionsList: [],
        userAction: 'hide',
        searchedDefinition: '',
        selectedDefinitionto: {
            "message": {
                "action": {
                    "text": "",
                    "url": "",
                    "docsFunctionIds": [""]
                },
                "cause": "",
                "deviation": "",
                "engineeringComment": "",
                "heading": "",
                "systemReaction": ""
            },
            "type": "",
            "group": "",
            "systemName": "",
            "id": 0
        },
        CUDApiResult: 'Default',
        CUDApisLoading: false, // 'Create / Update' defination APIs loading state,
        deFunctions: [],
        deFunctionsApiLoading: false,
    };
    return viewDefinitionsInitialState
}

// Slice
const slice = createSlice({
    name: 'allDefinitions',
    initialState: getInitialState(null),
    reducers: {
        updateSearchedDefinition: (state, action) => {
            state.searchedDefinition = action.payload
        },
        updateSelectedDefinition: (state, action) => {
            state.selectedDefinitionto = action.payload;
        },
        updateSelectedUserAction: (state, action) => {
            state.userAction = action.payload
        },
        updateAllDefinitions: (state, action) => {
            state.allDefinitionsList = action.payload
        },
        updateDefinitionsApiLoading: (state, action) => {
            state.isLoading = action.payload
        },
        updateCUDApisLoading: (state, action) => {
            state.CUDApisLoading = action.payload
        },
        updateCUDApisResult: (state, action) => {
            state.CUDApiResult = action.payload
        },
        updateAllDeFunctions: (state, action) => {
            state.deFunctions = action.payload
        },
        updateDeFunctionsApiLoading: (state, action) => {
            state.deFunctionsApiLoading = action.payload
        },
    },
});
export default slice.reducer

// Actions
const { updateSearchedDefinition, updateSelectedDefinition, updateSelectedUserAction, updateAllDefinitions,
    updateDefinitionsApiLoading, updateCUDApisLoading, updateCUDApisResult, updateAllDeFunctions, updateDeFunctionsApiLoading } = slice.actions

export const _updateSearchedDefinition = (value: string) => async (dispatch: any) => {
    try {
        dispatch(updateSearchedDefinition(value));
    } catch (e) {
        return (console.error(e));
    }
}

export const _updateSelectedDefinition = (data: Definitionto) => async (dispatch: any) => {
    try {
        dispatch(updateSelectedDefinition(data));
    } catch (e) {
        return (console.error(e));
    }
}

export const _updateSelectedUserAction = (data: FormStateType) => async (dispatch: any) => {
    try {
        dispatch(updateSelectedUserAction(data));
    } catch (e) {
        return console.error(e);
    }
}

export const _saveDefinition = (formData: Definitionto) => async (dispatch: any) => {
    dispatch(updateCUDApisLoading(true))
    try {
        await createDefinition(formData)
        dispatch(updateCUDApisResult('succeeded'));
        dispatch(updateCUDApisLoading(false))
        dispatch(_updateAllDefinitions());
        dispatch(_updateSelectedDefinition(formData))

    } catch (e) {
        return (console.error(e), dispatch(updateCUDApisLoading(false)), dispatch(updateCUDApisResult('failed')));
    }
}

export const _updateDefinition = (formData: Definitionto) => async (dispatch: any) => {
    dispatch(updateCUDApisLoading(true))
    try {
        await updateDefinition(formData)
        dispatch(updateCUDApisResult('succeeded'));
        dispatch(updateCUDApisLoading(false))
        dispatch(_updateAllDefinitions());
        dispatch(_updateSelectedDefinition(formData))

    } catch (e) {
        return (console.error(e), dispatch(updateCUDApisLoading(false)), dispatch(updateCUDApisResult('failed')));
    }
}

export const _updateAllDeFunctions = () => async (dispatch: any) => {
    dispatch(updateDeFunctionsApiLoading(true))
    try {
        const res = await getAllFunctions()
        dispatch(updateAllDeFunctions(res.data));
        dispatch(updateDeFunctionsApiLoading(false))
    } catch (e) {
        return (console.error(e), dispatch(updateDeFunctionsApiLoading(false)));
    }
}


export const _updateAllDefinitions = () => async (dispatch: any) => {
    
    try {
        dispatch(updateDefinitionsApiLoading(true)) 
        const res = await getCsdDefinitions()
        dispatch(updateAllDefinitions(res.data));
        dispatch(updateDefinitionsApiLoading(false))
    } catch (e) {
        console.error(e)
        dispatch(updateDefinitionsApiLoading(false))
    }
}


export const _updateCUDApiResult = updateCUDApisResult;