// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Spinner, Toast, useToast } from "@cd/sdds-common-components-react";
import SearchBar from "../../components/SearchBar";
import ViewDefinitionForm from "../../components/Definitions/ViewDefinitionForm";
import ViewDefinitionRow from "../../components/Definitions/ViewDefinitionRow";
import CreateDocsUserModal from "../../components/DocsUser"
import { _updateSelectedDefinition, _updateSelectedUserAction, _updateAllDefinitions } from "../../redux/store/DefinitionsStore";

import { orderBy, intersection } from "lodash";
import "./csdDefinitions.scss"
import { InfoIcon } from "@cd/sdds-common-components-react/lib/esm/components/assets";


type CsdDefinitionProps = {
    developerAccess: boolean
}

const CsdDefinitionsPage = (props: CsdDefinitionProps) => {
    const { developerAccess } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { toastQueue, addToast } = useToast();
    const { isLoading, CUDApisLoading} = useAppSelector(state => state.allDefinitionsStore);

    const [displayedTableData, setDisplayedTableData] = useState<Array<Definitionto>>([]);
    const definitionControl: DefinitionsState = useAppSelector<DefinitionsState>(state => state.allDefinitionsStore)

    const [closeView, setCloseView] = useState<boolean>(true);
    const [searchID, setSearchID] = useState<string>(definitionControl.searchedDefinition);
    const [searchSystem, setSearchSystem] = useState<string>(definitionControl.searchedDefinition);
    const [saveDefinition, setSaveDefinition] = useState<boolean>(true);
    const [updateDefinition, setUpdateDefinition] = useState<boolean>(true)

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [selectedItem, setSelectedItem] = useState<number | null>(null);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };


    //reset selected data if user action is create
    const onAddDefinitionClick = () => {
        setCloseView(false)
        setSaveDefinition(false)
        setUpdateDefinition(false)
        dispatch(_updateSelectedUserAction('create'));
        dispatch(_updateSelectedDefinition({
            "systemName": "",
            "id": 0,
            "group": "",
            "type": "",
            "message": {
                "heading": "",
                "deviation": "",
                "cause": "",
                "systemReaction": "",
                "action": {
                    "text": "",
                    "url": "",
                    "docsFunctionIds": [""]
                },
                "engineeringComment": ""
            }
        }))
    }

    //form cancel action
    const onCancel = (userAction: FormStateType) => {
        if (userAction === 'create' || userAction === 'edit') {
            dispatch(_updateSelectedUserAction('hide'));
        } else {
            dispatch(_updateSelectedUserAction('view'));
         }
        }

    const showDefinition = (index: number) => {
        dispatch(_updateSelectedUserAction('view'));
        dispatch(_updateSelectedDefinition(displayedTableData[index]))
        setSelectedItem(displayedTableData[index].id);
        setCloseView(false)
        setSaveDefinition(false)
        setUpdateDefinition(false)
    }

    //sorts & filters table data based on existing search selection
    const sortedFilteredTableData = (): Array<Definitionto> => {
        var systems: Array<Definitionto> = orderBy(definitionControl.allDefinitionsList, ['systemName'], ['asc']).filter((obj) => {
            let showSystem = searchSystem.length > 0 ? obj?.systemName?.toLowerCase().indexOf(searchSystem.toLowerCase()) !== -1 : true;
            return (
                showSystem
            );
        });

        var ids: Array<Definitionto> = orderBy(definitionControl.allDefinitionsList, ['id'], ['asc']).filter((obj) => {
            let showId = searchID.length > 0 ? obj?.id?.toString().indexOf(searchID) !== -1 : true;
            return (
                showId
            );
        });

        var intersect = intersection(systems, ids);

        return intersect;
    }

    //get updated values for the selected definition after table reload
    useEffect(() => {
        if (displayedTableData.length === 0) return;
        if (definitionControl.selectedDefinitionto.id !== 0) {
            dispatch(_updateSelectedUserAction('view'));
        }

    }, [displayedTableData]);


    //filter table data based on search
    useEffect(() => {
        setDisplayedTableData(sortedFilteredTableData());
    }, [searchID, searchSystem])

    //sort table after every reload of data & apply existing filter
    useEffect(() => {
        if (!definitionControl.isLoading)
            setDisplayedTableData(sortedFilteredTableData());
    }, [definitionControl.isLoading]);

    useEffect(() => {
        dispatch(_updateAllDefinitions());
    }, []);


    const closeViewDefinitionForm = (close: boolean) => {
        if (close === true) {
            addToast({
                title: "CSD definition deleted successfully", text: "",
                type: "success"
            });
        }
        setCloseView(close);
    };

    const saveDefinitionForm = (saveDefinition: boolean) => {
      if (saveDefinition === true) {
          addToast({
              title: "CSD definition created successfully", text: "",
              type: "success"
          });
      }
      setSaveDefinition(saveDefinition);
  };

  const updateDefinitionForm = (updateDefinition: boolean) => {
    if (updateDefinition === true) {
        addToast({
            title: "CSD definition updated successfully", text: "",
            type: "success"
        });
    }
    setUpdateDefinition(updateDefinition);
};

    return (
      <>
        <div className="definitions">
          <div className="wrapper">
            <div className="sdds-col-xs-12 sdds-col-sm-12">
              <div className="sdds-row space-between">
                <div className="sdds-headline-05">
                  {t("CONNECTED SERVICE DEVIATION DEFINITIONS")}{" "}
                </div>
                <div className="flex">
                  <button
                    className="sdds-btn sdds-btn-secondary sdds-btn-sm block"
                    onClick={openModal}
                    title={t("Create DoCS User")}
                  >
                    {t("Create DoCS User")}
                  </button>
                  <button
                    className="sdds-btn sdds-btn-primary sdds-btn-sm"
                    title={t("Create Definations")}
                    onClick={() => onAddDefinitionClick()}
                  >
                    {t("Create Definition")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="details-view">
            <div>
              <div className="table-headers sdds-headline-07">
                <div className="name-column">
                  {t("TS_core:SystemName")}
                  {<SearchBar setSearchedValue={setSearchSystem} />}
                </div>
                <div className="id-column">
                  {t("TS_core:ID")}
                  {<SearchBar setSearchedValue={setSearchID} />}
                </div>
              </div>
              <div className="details-view-table-container">
                <>
                  {!isLoading &&
                    displayedTableData.map((data, index) => {
                      return (
                        <ViewDefinitionRow
                          id={data.id}
                          systemName={data?.systemName || ""}
                          showDetails={() => showDefinition(index)}
                          key={index}
                          isLoading={isLoading}
                          selectedItem={selectedItem}
                        />
                      );
                    })}
                  {isLoading && <Spinner />}
                </>
              </div>
            </div>
            {/* View Definition section */}
            
            {!closeView && !saveDefinition && !updateDefinition && definitionControl.userAction !== "hide" ? (
              <>
              {!CUDApisLoading && 
                  <ViewDefinitionForm
                    userAction={"view"}
                    onCancel={onCancel}
                    onClose={closeViewDefinitionForm}
                    developerAccess={developerAccess}
                    isLoading={definitionControl.CUDApisLoading}
                    onSaveDefinition={saveDefinitionForm}
                    updateDefinitionForm={updateDefinitionForm}
                  />
              }
              {CUDApisLoading && <Spinner />}
              </>
            ) : (
              <>
                <div className="disclaimer tds-body-02">
                  <InfoIcon />
                  <span>
                    You need to create a DoCS user before you can start
                    registering CSDs . Please create one if you have not already
                    done so.
                  </span>
                </div>
              </>
            )}
            <Toast toastQueue={toastQueue} />
          </div>
          <CreateDocsUserModal
            isOpen={isModalOpen}
            onClose={handleModalClose}
          />
        </div>
      </>
    );
}
export default CsdDefinitionsPage;
