import { createSlice } from '@reduxjs/toolkit'
import { getCsdOverviewDetails, getCsdOverviewDetailsProductEntity, getCsdOverviewDetailsUser } from '../../adapters/CsdFunctionsAdapter';

const getInitialState = (): OverviewDetailsStateType => {
    const overviewDetailsState: OverviewDetailsStateType = {
        csdIdTime: [],
        equipmentImpactOverview: [],
        userImpactOverview: [],
        startTime: '',
        endTime: '',
        csdHeaders: [],
        paginationToken: '{}',
        paginationTokenProductEntity: '{}',
        paginationTokenUser: '{}',
        isLoading: false,
        hasError: false,
        errorMessage: '',
        isReset:true,
    };
    return overviewDetailsState;
}

// Slice
const slice = createSlice({
    name: 'overviewDetails',
    initialState: getInitialState(),
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        resetData: (state) => {
            state.csdIdTime = [];
            state.equipmentImpactOverview = [];
            state.userImpactOverview = [];
            state.paginationToken = '{}';
            state.paginationTokenProductEntity = '{}';
            state.paginationTokenUser = '{}';
            state.isReset = !state.isReset;
        },
        updateOverviewDetails: (state, action) => {
            const { attributes, userAction, paginationToken } = action.payload;
            let moreData: any = [];
            state.paginationToken = paginationToken;

            if (attributes !== null) {
                var data: Array<MetaIdentifier[]> = attributes;
                moreData = data.map((item: MetaIdentifier[]) => {
                    return { systemName: item[0].value, definitionId: item[1].value, time: item[3].value, id: item[2].value }
                });
            }

            if (userAction !== 'append') {
                state.csdIdTime = [];
            }
            //append and sort from latest to oldest in message table
            state.csdIdTime = state.csdIdTime.concat(moreData);
            state.csdIdTime = state.csdIdTime.sort((a:any, b:any) => new Date(b.time).getTime() - new Date(a.time).getTime());

        },
        updateOverviewDetailsProdEntity: (state, action) => {
            const { attributes, userAction, paginationToken } = action.payload;
            let moreData: any = [];
            state.paginationTokenProductEntity = paginationToken;

            if (attributes !== null) {
                var data: Array<MetaIdentifier[]> = attributes;
                // Create timestamp array
                moreData = data.map((systems) => {
                    var equipmentImpacts: CsdImpactType = { data: '', timestamps: [] };
                    systems.forEach((s) => {
                        if (s.type === "Timestamp")
                            equipmentImpacts.timestamps.push(s.value);
                        else
                            equipmentImpacts.data = s.value
                    });
                    // Sort time stamp array
                    var sortedTimeArray = equipmentImpacts.timestamps.sort(compareDateTime);
                    return ({ data: equipmentImpacts.data, timestamps: sortedTimeArray });
                });
            }

            if (userAction !== 'append') {
                state.equipmentImpactOverview = [];
            }
            state.equipmentImpactOverview = state.equipmentImpactOverview.concat(moreData);
        },        
        updateOverviewDetailsUser: (state, action) => {
            const { attributes, userAction, paginationToken } = action.payload;
            let moreData: any = [];
            state.paginationTokenUser = paginationToken;

            if (attributes !== null) {
                var data: Array<MetaIdentifier[]> = attributes;
                // Create timestamp array
                moreData = data.map((systems) => {
                    var userImpacts: CsdImpactType = { data: '', timestamps: [] };
                    systems.forEach((s) => {
                        if (s.type === "Timestamp")
                            userImpacts.timestamps.push(s.value);
                        else
                            userImpacts.data = s.value
                    });
                    // Sort time stamp array                    
                    var sortedTimeArray = userImpacts.timestamps.sort(compareDateTime);
                    return { data: userImpacts.data, timestamps: sortedTimeArray };
                });
            }
            if (userAction !== 'append') {
                state.userImpactOverview = [];
            }
            state.userImpactOverview = state.userImpactOverview.concat(moreData);
        },
        updateError: (state, action) => {
            state.hasError = action.payload !== '';
            state.errorMessage = action.payload;
            state.isLoading = false;
        }
    }
});
export default slice.reducer

// Actions
const { updateOverviewDetails, updateOverviewDetailsProdEntity, updateOverviewDetailsUser, setIsLoading, updateError, resetData } = slice.actions

export const getOverviewDetails = (request: OverviewDetailsType, action?:string) => async (dispatch: any) => {
    try {
        dispatch(setIsLoading(true));        
        const res = await getCsdOverviewDetails(request);
        if (res.data) {                    
                res.data.userAction= action;
                dispatch(updateOverviewDetails(res.data))
        } 
        dispatch(setIsLoading(false));
    } catch (e: any) {
        console.log('e', e)
        dispatch(setIsLoading(false));
        dispatch(updateError(e.message));
    }
}

export const getOverviewDetailsProdEntity = (request: OverviewDetailsType, action?:string) => async (dispatch: any) => {
    try {
        dispatch(setIsLoading(true));
        const detailsRes = await getCsdOverviewDetailsProductEntity(request);
        if (detailsRes.data){ 
            detailsRes.data.userAction= action;           
            dispatch(updateOverviewDetailsProdEntity(detailsRes.data))
        }
           
            dispatch(setIsLoading(false));
    } catch (e: any) {
        console.log('e', e)
        dispatch(setIsLoading(false));
        dispatch(updateError(e.message));
    }
}

export const getOverviewDetailsUser = (request: OverviewDetailsType, action?:string) => async (dispatch: any) => {
    try {
        dispatch(setIsLoading(true));
        const detailsRes = await getCsdOverviewDetailsUser(request);
        if (detailsRes.data){
            detailsRes.data.userAction= action;  
            dispatch(updateOverviewDetailsUser(detailsRes.data))
        }
            
            dispatch(setIsLoading(false));
    } catch (e: any) {
        console.log('e', e)
        dispatch(setIsLoading(false));
        dispatch(updateError(e.message));
    }
}

export const clearOverviewDetails = () => async (dispatch: any) => {
    try {       
        dispatch(resetData());       
    } catch (e: any) {
        console.log('e', e)
        dispatch(updateError(e.message));
    }
}

const compareDateTime = (a: string, b: string) => {
    const dateA = new Date(a);
    const dateB = new Date(b);

    return dateB.getTime() - dateA.getTime();
};

