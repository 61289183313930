import { createSlice } from '@reduxjs/toolkit'

const getInitialState = (): CsdIdSelectionType => {
    const systemCsdIdSelectionTypeState: CsdIdSelectionType = {
        systemName: undefined,
        csdId: undefined
    };
    return systemCsdIdSelectionTypeState;
}
// Slice
const slice = createSlice({
    name: 'systemCsdIdSelection',
    initialState: getInitialState(),
    reducers: {
        updateSystemCsdIdSelection: (state, action) => {
            const { systemName, csdId } = action.payload;
            state.systemName = systemName;
            state.csdId = csdId;
        }
    }
});
export default slice.reducer

// Actions
const { updateSystemCsdIdSelection } = slice.actions

export const setSelectedCsdId = (systemName: string, csdId: number) => async (dispatch: any) => {
    try {
        dispatch(updateSystemCsdIdSelection({ systemName: systemName, csdId: csdId }))

    } catch (e: any) {
    }
}