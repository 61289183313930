import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../Definitions/definitionForm.scss';
import { InfoIcon } from '@cd/sdds-common-components-react/lib/esm/components/assets';
import { useAppSelector, useAppDispatch } from "../../../redux/store";
import { _saveDefinition, _updateAllDeFunctions, _updateAllDefinitions, _updateDefinition, _updateSelectedUserAction } from '../../../redux/store/DefinitionsStore';
import ConfirmationDialog from '../../ConfirmationDialog';
import { deleteCsdDefinition } from '../../../adapters/CsdFunctionsAdapter';
import { Spinner, Toast, useToast } from "@cd/sdds-common-components-react";
import { TdsTextField } from '@scania/tegel-react'
import _ from 'lodash';


type DefinitionFormProps = {
	userAction: FormStateType,
	onCancel: Function,
	developerAccess: boolean,
	onClose: (value: boolean) => void,
	isLoading: boolean,
	onSaveDefinition: (value: boolean) => void,
	updateDefinitionForm: (value: boolean) => void
}

const ViewDefinitionForm = (props: DefinitionFormProps) => {

	const { onCancel, onClose, onSaveDefinition, updateDefinitionForm, isLoading } = props;
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const definitionControl: DefinitionsState = useAppSelector<DefinitionsState>(state => state.allDefinitionsStore)
	const { selectedDefinitionto } = useAppSelector<DefinitionsState>(state => state.allDefinitionsStore)
	const { toastQueue, addToast } = useToast()
	const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
	const [deFunctions, setDeFunctions] = useState<Array<FunctionType>>([]);
	// const [defaultOptions, setDefaultOptions] = useState<string>("");
	const [functionIdsToNames, setFunctionIdsToNames] = useState<string>("");

	const [showSpinner, setShowSpinner] = useState<boolean>(false);
	const [systemNameInputValue, setSystemNameInputValue] = useState<string>('')
	const [idInputValue, setIdInputValue] = useState<number>(0)
	const [groupInputValue, setGroupInputValue] = useState<string>('')
	const [typeInputValue, setTypeInputValue] = useState<string>('')
	const [headingInputValue, setHeadingInputValue] = useState<string>('')
	const [deviationInputValue, setDeviationInputValue] = useState<string>('')
	const [causeInputValue, setCauseInputValue] = useState<string>('')
	const [systemReactionInputValue, setSystemReactionInputValue] = useState<string>('')
	const [textInputValue, setTextInputValue] = useState<string>('')
	const [urlInputValue, setUrlInputValue] = useState<string>('')
	const [selectedValues, setSelectedValues] = useState(['']); // Pre-selected
	const [engineeringCommentInputValue, setEngineeringCommentInputValue] = useState<string>('')

	// Handle input validation
	const [errorSystemName, setErrorSystemName] = useState<TextfieldErrorType>('success');
	const [errorId, setErrorId] = useState<TextfieldErrorType>('success');
	const [validationErrorType, setValidationErrorType] = useState<TextfieldErrorType>('success');
	const [validationErrorGroup, setValidationErrorGroup] = useState<TextfieldErrorType>('success');
	const [validationErrorHeading, setValidationErrorHeading] = useState<TextfieldErrorType>('success');
	const [validationErrorDeviation, setValidationErrorDeviation] = useState<TextfieldErrorType>('success');
	const [validationErrorCause, setValidationErrorCause] = useState<TextfieldErrorType>('success');
	const [validationErrorSystemReaction, setValidationErrorSystemReaction] = useState<TextfieldErrorType>('success');
	const [validationErrorText, setValidationErrorText] = useState<TextfieldErrorType>('success');

	const [helperText, setHelperText] = useState('');
	const [helperTextId, setHelperTextId] = useState('');

	const onDeleteHandler = () => {
		setShowDeleteDialog(true);
	}

	const onDelete = async () => {
		try {
			if (selectedDefinitionto.systemName === null || selectedDefinitionto.systemName === undefined
				|| selectedDefinitionto.id === null || selectedDefinitionto.id === undefined)
				return;

			setShowSpinner(true);
			const result = await deleteCsdDefinition(selectedDefinitionto.systemName, selectedDefinitionto.id.toString());
			if (result.status === 200) {
				addToast({
					title: "CSD definition deleted successfully", text: "",
					type: "success"
				});
				setShowSpinner(false);
				onClose(true);
				dispatch(_updateAllDefinitions());
			}
		} catch (e: any) {
			setShowSpinner(false);
			addToast({
				title: "CSD definition deletion failed", text: "Please try again or contact cstt support",
				type: "error"
			});
			onClose(false);
		}
	};

	const resetInputFields = () => {
		setSystemNameInputValue('')
		setIdInputValue(0)
		setTypeInputValue('')
		setGroupInputValue('')
		setHeadingInputValue('')
		setDeviationInputValue('')
		setCauseInputValue('')
		setSystemReactionInputValue('')
		setTextInputValue('')
		setUrlInputValue('')
		setSelectedValues([])
		setEngineeringCommentInputValue('')
		setHelperText('')
		setHelperTextId('')
		setErrorSystemName('success')
		setErrorId('success')
		setValidationErrorDeviation('success')
		setValidationErrorCause('success')
		setValidationErrorGroup('success')
		setValidationErrorHeading('success')
		setValidationErrorSystemReaction('success')
		setValidationErrorText('success')
		setValidationErrorType('success')
		getFieldValues()
	};

	const systemNameIdExist = (systemName: string, id: number): boolean => {
		for (const definition of definitionControl.allDefinitionsList) {
			if (definition.systemName === systemName) {
				if (definition.id === id) {
					return true;
				}
			}
		}
		return false;
	};


	//Get functions selected in the form
	const getSelectedFunctions = () => {
		const selectedFunctions = document?.getElementById("multi-select")?.getAttribute("multi-selected-values");
		const selectedFunctionsNoLeftBracket = selectedFunctions?.replace("[", "");
		const selectedFunctionsNoBrackets = selectedFunctionsNoLeftBracket?.replace("]", "");
		const selectedValuesCleaned = selectedFunctionsNoBrackets?.replaceAll('"', "");
		let selectedSplittedValues = selectedValuesCleaned?.split(",");
		if (selectedSplittedValues === undefined || (selectedSplittedValues.length === 1 && selectedSplittedValues[0] === ''))
			selectedSplittedValues = undefined;
		return selectedSplittedValues;
	}

	// Get all field values from form
	const getFieldValues = () => {

		let storeFuncations = getSelectedFunctions();
		const selectedValues = storeFuncations
		const formData: Definitionto = {
			systemName: systemNameInputValue,
			id: idInputValue,
			group: groupInputValue,
			type: typeInputValue,
			message: {
				heading: headingInputValue,
				deviation: deviationInputValue,
				cause: causeInputValue,
				systemReaction: systemReactionInputValue,
				action: {
					text: textInputValue,
					url: urlInputValue,
					docsFunctionIds: selectedValues,
				},
				engineeringComment: engineeringCommentInputValue
			}
		}
		return formData;
	}

	useEffect(() => {
		setSystemNameInputValue(selectedDefinitionto.systemName ? selectedDefinitionto.systemName : '')
		setIdInputValue(selectedDefinitionto?.id);
		setGroupInputValue(selectedDefinitionto?.group ? selectedDefinitionto?.group : '');
		setTypeInputValue(selectedDefinitionto?.type ? selectedDefinitionto?.type : '');
		setHeadingInputValue(selectedDefinitionto?.message.heading ? selectedDefinitionto?.message.heading : '');
		setDeviationInputValue(selectedDefinitionto?.message.deviation ? selectedDefinitionto?.message.deviation : '');
		setCauseInputValue(selectedDefinitionto?.message.cause ? selectedDefinitionto?.message.cause : '');
		setSystemReactionInputValue(selectedDefinitionto?.message.systemReaction ? selectedDefinitionto?.message.systemReaction : '');
		setTextInputValue(selectedDefinitionto?.message.action.text ? selectedDefinitionto?.message.action.text : '');
		setUrlInputValue(selectedDefinitionto?.message.action.url ? selectedDefinitionto?.message.action.url : '');
		setSelectedValues(selectedDefinitionto?.message.action.docsFunctionIds ? selectedDefinitionto?.message.action.docsFunctionIds : []);
		setEngineeringCommentInputValue(selectedDefinitionto?.message.engineeringComment ? selectedDefinitionto?.message.engineeringComment : '');
	}, [selectedDefinitionto])



	const onSave = (userAction: FormStateType) => {
		console.log('save', userAction);
		if (userAction === 'create') {
			setErrorSystemName('success')
			setErrorId('success')
			setHelperText('')
			setHelperTextId('')
			if (systemNameIdExist(systemNameInputValue, idInputValue)) {
				setErrorSystemName('error');
				setErrorId('error');
				setHelperTextId('Id must be unique in combination with system name');
				return;
			}
			if (!validForm())
				return;
			dispatch(_saveDefinition(getFieldValues()))
			onSaveDefinition(true)
		}

		if (userAction === 'edit') {
			console.log('', errorSystemName);
			if (!validForm())
				return;
			dispatch(_updateDefinition(getFieldValues()))
			updateDefinitionForm(true)
		}
		resetInputFields()
		dispatch(_updateAllDefinitions())
		dispatch(_updateAllDeFunctions)
		dispatch(_updateSelectedUserAction('hide'))
		onSaveDefinition(false)
		updateDefinitionForm(false)
	}

	const validForm = (): boolean => {
		setHelperText("Entry must be greater than 3 characters");

		var hasError = false;

		if (systemNameInputValue.length < 3) {
			setErrorSystemName("error");
			hasError = true;
		}

		if (idInputValue <= 0) {
			setHelperTextId('ID must be greater than 0')
			setErrorId("error");
			hasError = true;
		}

		if (typeInputValue.length < 3) {
			setValidationErrorType("error");
			hasError = true;
		}

		if (groupInputValue.length < 3) {
			setValidationErrorGroup("error");
			hasError = true;
		}

		if (headingInputValue.length < 3) {
			setValidationErrorHeading("error");
			hasError = true;
		}

		if (deviationInputValue.length < 3) {
			setValidationErrorDeviation("error");
			hasError = true;
		}

		if (textInputValue.length < 3) {
			setValidationErrorText("error");
			hasError = true;
		}

		if (causeInputValue.length < 3) {
			setValidationErrorCause("error");
			hasError = true;
		}

		if (systemReactionInputValue.length < 3) {
			setValidationErrorSystemReaction("error");
			hasError = true;
		}

		if (textInputValue.length < 3) {
			setValidationErrorText("error");
			hasError = true;
		}

		return !hasError;
	};

	useEffect(() => {
		dispatch(_updateAllDeFunctions());
	}, []);



	const handleChange = (event: any) => {
		const newValue = event.target.value;
		setSelectedValues(selectedValues.includes(newValue) ? selectedValues.filter((v) => v !== newValue) : [...selectedValues, newValue]);
	};
	//processing de functions before displaying
	useEffect(() => {
		const functionObjects = _.map(definitionControl.deFunctions, (obj, key) => {
			var aFunction = { value: "", label: "" };
			aFunction.value = obj.id;
			aFunction.label = obj.name;
			return aFunction
		});
		setDeFunctions(functionObjects);
	}, [definitionControl.deFunctions]);

	// Set default options for functions field
	useEffect(() => {
		let functions = [''];
		functions.splice(0);
		_.map(definitionControl.selectedDefinitionto.message.action.docsFunctionIds, (functionId) => {
			var foundFunction = deFunctions.find(element => element.value === functionId);
			if (foundFunction === undefined)
				return "";
			functions.push(foundFunction.value);
			return functions;
		});

		var selectedValues = "\"[";
		functions.forEach(f => {
			selectedValues += "'" + f + "',";
		});

		if (functions.length < 1) {
			setSelectedValues([]);
			return;
		}
		selectedValues = selectedValues.substring(0, selectedValues.lastIndexOf(","));
		selectedValues += "]\"";
		setSelectedValues([selectedValues]);
	}, [deFunctions, definitionControl.selectedDefinitionto.id]);

	// Map functionId to function name
	useEffect(() => {
		var funcIds = "";
		_.map(definitionControl.selectedDefinitionto.message.action.docsFunctionIds, (id) => {
			var funcType = deFunctions.find(element => element.value === id);
			if (funcType !== undefined) {
				funcIds += funcType.label + ", ";
			}
		});
		funcIds = funcIds.slice(0, funcIds.length - 2);
		setFunctionIdsToNames(funcIds);
	}, [deFunctions, definitionControl.selectedDefinitionto.message.action.docsFunctionIds]);

	return (
		<>
			<div className="definition-form">
				{showSpinner && !isLoading && (
					<div className="loader">
						<Spinner size="lg" />
					</div>
				)}
				{!isLoading &&
					!showSpinner &&
					definitionControl.userAction !== "hide" && (
						<div>
							<div className="form-top-bar space-between">
								<div className="sdds-headline-05">
									{t("Definition Details")}
								</div>
								<div className="flex">
									{
										<>
											<button
												disabled={
													definitionControl.userAction === "create" ||
													definitionControl.userAction === "edit"
												}
												className="sdds-btn sdds-btn-secondary sdds-btn-sm actionButton"
												title={t("TS_core:EditButton")}
												onClick={() => {
													dispatch(_updateSelectedUserAction("edit"));
												}}
											>
												{t("TS_core:Edit")}
											</button>
											<button
												disabled={definitionControl.userAction === "create"}
												className="sdds-btn sdds-btn-secondary sdds-btn-sm actionButton"
												title={t("TS_core:Deregister")}
												onClick={onDeleteHandler}
											>
												{t("TS_core:Deregister")}
											</button>
										</>
									}
								</div>
							</div>
							<div className="form-fields">
								<div className="form-field">
									<div className="form-label sdds-headline-07">
										{t("System Name")} *
										<div className="info-icon">
											<div className="translation-pending-icon">
												<InfoIcon />
												<div className="custom-tooltip">
													{t(
														"System name is typically your microservice name, or if you have a larger gathering of microservices with a common function which is named."
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="form-value">
										<div className="view-state">
											{definitionControl.userAction === "create" && (
												<TdsTextField
													id="systemName"
													autofocus={true}
													size="md"
													helper={helperText}
													state={errorSystemName}
													placeholder={t("The name of the System")}
													value={systemNameInputValue}
													onTdsChange={(event: any) =>
														setSystemNameInputValue(event.target.value)
													}
												></TdsTextField>
											)}

											{definitionControl.userAction === "edit" && (
												<TdsTextField
													read-only
													id="systemName"
													type="text"
													size="md"
													state={errorSystemName}
													helper={helperText}
													value={systemNameInputValue}
													onTdsChange={(event: any) =>
														setSystemNameInputValue(event.target.value)
													}
												></TdsTextField>
											)}
											{definitionControl.userAction === "view" && (
												<>
													{definitionControl.selectedDefinitionto?.systemName}
												</>
											)}
										</div>
									</div>
								</div>

								<div className="form-field">
									<div className="form-label sdds-headline-07">
										{t("ID")} *
									</div>
									<div className="form-value">
										<div className="view-state">
											{definitionControl.userAction === "create" && (
												<TdsTextField
													id="id"
													size="md"
													min-length="3"
													helper={helperTextId}
													state={errorId}
													placeholder={t("The ID of the error")}
													value={
														idInputValue.toString()
															? idInputValue.toString()
															: ""
													}
													onTdsChange={(event) =>
														setIdInputValue(
															parseInt(event.target.value)
																? parseInt(event.target.value)
																: 0
														)
													}
												></TdsTextField>
											)}
											{definitionControl.userAction === "edit" && (
												<TdsTextField
													read-only
													id="id"
													state={errorId}
													helper={helperTextId}
													type="text"
													size="md"
													value={idInputValue.toString()}
													onTdsChange={(event) =>
														setIdInputValue(parseInt(event.target.value))
													}
													no-min-width
												></TdsTextField>
											)}
											{definitionControl.userAction === "view" && (
												<>{definitionControl.selectedDefinitionto?.id}</>
											)}
										</div>
									</div>
								</div>
								<div className="form-field">
									<div className="form-label sdds-headline-07">
										{t("Type")} *
										<div className="info-icon">
											<div className="translation-pending-icon">
												<InfoIcon />
												<div className="custom-tooltip">
													{t(
														"Type could be something you want to add for handling a specific kind of error (Like user errors, subscription errors, vehicle related, etc...)"
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="form-value">
										<div className="view-state">
											{definitionControl.userAction === "create" && (
												<TdsTextField
													id="id"
													size="md"
													placeholder={t(
														"What global main type does the deviation belong to?"
													)}
													helper={helperText}
													state={validationErrorType}
													value={typeInputValue}
													onTdsChange={(event) =>
														setTypeInputValue(event.target.value)
													}
												/>
											)}
											{definitionControl.userAction === "edit" && (
												<TdsTextField
													id="type"
													type="text"
													helper={helperText}
													state={validationErrorType}
													size="md"
													value={typeInputValue}
													onTdsChange={(event) =>
														setTypeInputValue(event.target.value)
													}
												></TdsTextField>
											)}
											{definitionControl.userAction === "view" && (
												<>{selectedDefinitionto?.type}</>
											)}
										</div>
									</div>
								</div>
								<div className="form-field">
									<div className="form-label sdds-headline-07">
										{t("Group")} *
										<div className="info-icon">
											<div className="translation-pending-icon">
												<InfoIcon />
												<div className="custom-tooltip">
													{t(
														"Group could be any understandable grouping of choice (maybe component name, lambda name etc..) so, the Type and Group fields gives the possibillity for each system to arrange grouping and typing for their errors."
													)}
												</div>
											</div>
										</div>
									</div>
									<div className="form-value">
										<div className="view-state">
											{definitionControl.userAction === "create" && (
												<TdsTextField
													id="id"
													size="md"
													placeholder={t(
														"What local group does the deviation belong to ?"
													)}
													value={groupInputValue}
													helper={helperText}
													state={validationErrorGroup}
													onTdsChange={(event) =>
														setGroupInputValue(event.target.value)
													}
												></TdsTextField>
											)}
											{definitionControl.userAction === "edit" && (
												<TdsTextField
													id="group"
													type="text"
													size="md"
													helper={helperText}
													state={validationErrorGroup}
													value={groupInputValue}
													onTdsChange={(event) =>
														setGroupInputValue(event.target.value)
													}
												></TdsTextField>
											)}
											{definitionControl.userAction === "view" && (
												<>{definitionControl.selectedDefinitionto?.group}</>
											)}
										</div>
									</div>
								</div>

								{/* TODO Messages */}
								<div className="message">
									<div className="sdds-headline-05">{t("Message")}</div>
									<div className="form-field">
										<div className="form-label sdds-headline-07">
											{t("Heading")} *
										</div>
										<div className="form-value">
											<div className="view-state">
												{definitionControl.userAction === "create" && (
													<TdsTextField
														id="id"
														size="md"
														placeholder={t("A short description")}
														helper={helperText}
														value={headingInputValue}
														state={validationErrorHeading}
														onTdsChange={(event) =>
															setHeadingInputValue(event.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "edit" && (
													<TdsTextField
														id="heading"
														type="text"
														helper={helperText}
														value={headingInputValue}
														state={validationErrorHeading}
														onTdsChange={(event) =>
															setHeadingInputValue(event.target.value)
														}
														size="md"
													></TdsTextField>
												)}
												{definitionControl.userAction === "view" && (
													<>
														{
															definitionControl.selectedDefinitionto?.message
																.heading
														}
													</>
												)}
											</div>
										</div>
									</div>
									<div className="form-field">
										<div className="form-label sdds-headline-07">
											{t("Deviation")} *
										</div>
										<div className="form-value">
											<div className="view-state">
												{definitionControl.userAction === "create" && (
													<TdsTextField
														id="id"
														size="md"
														placeholder={t(
															"How is the deviation recognized?"
														)}
														helper={helperText}
														value={deviationInputValue}
														state={validationErrorDeviation}
														onTdsChange={(e) =>
															setDeviationInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "edit" && (
													<TdsTextField
														id="deviation"
														type="text"
														helper={helperText}
														state={validationErrorDeviation}
														size="md"
														value={deviationInputValue}
														onTdsChange={(e) =>
															setDeviationInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "view" && (
													<>
														{
															definitionControl.selectedDefinitionto?.message
																.deviation
														}
													</>
												)}
											</div>
										</div>
									</div>
									<div className="form-field">
										<div className="form-label sdds-headline-07">
											{t("Cause")} *
										</div>
										<div className="form-value">
											<div className="view-state">
												{definitionControl.userAction === "create" && (
													<TdsTextField
														id="id"
														size="md"
														placeholder={t(
															"What is the underlying cause of the deviation?"
														)}
														helper={helperText}
														value={causeInputValue}
														state={validationErrorCause}
														onTdsChange={(e) =>
															setCauseInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "edit" && (
													<TdsTextField
														id="cause"
														type="text"
														size="md"
														helper={helperText}
														state={validationErrorCause}
														value={causeInputValue}
														onTdsChange={(e) =>
															setCauseInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "view" && (
													<>
														{
															definitionControl.selectedDefinitionto?.message
																.cause
														}
													</>
												)}
											</div>
										</div>
									</div>
									<div className="form-field">
										<div className="form-label sdds-headline-07">
											{t("System Reaction")} *
										</div>
										<div className="form-value">
											<div className="view-state">
												{definitionControl.userAction === "create" && (
													<TdsTextField
														id="id"
														size="md"
														placeholder={t(
															"How does the system react on the deviation?"
														)}
														helper={helperText}
														value={systemReactionInputValue}
														state={validationErrorSystemReaction}
														onTdsChange={(e) =>
															setSystemReactionInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "edit" && (
													<TdsTextField
														id="systemReaction"
														type="text"
														size="md"
														helper={helperText}
														state={validationErrorSystemReaction}
														value={systemReactionInputValue}
														onTdsChange={(e) =>
															setSystemReactionInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "view" && (
													<>
														{
															definitionControl.selectedDefinitionto?.message
																.systemReaction
														}
													</>
												)}
											</div>
										</div>
									</div>
									<div className="form-field">
										<div className="form-label sdds-headline-07">
											{t("Comment from Engineer ")}
										</div>
										<div className="form-value">
											<div className="view-state">
												{definitionControl.userAction === "create" && (
													<TdsTextField
														id="id"
														size="md"
														placeholder={t(
															"Any additional helpful comments from the engineer"
														)}
														helper={helperText}
														value={engineeringCommentInputValue}
														onTdsChange={(e) =>
															setEngineeringCommentInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "edit" && (
													<TdsTextField
														id="engineeringComment"
														type="text"
														size="md"
														helper={helperText}
														value={engineeringCommentInputValue}
														onTdsChange={(e) =>
															setEngineeringCommentInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "view" && (
													<>
														{
															definitionControl.selectedDefinitionto?.message
																.engineeringComment
														}
													</>
												)}
											</div>
										</div>
									</div>
								</div>

								{/* TODO Actions */}
								<div className="actions">
									<div className="sdds-headline-05">{t("Actions")}</div>
									<div className="form-field">
										<div className="form-label sdds-headline-07">
											{t("Text")} *
										</div>
										<div className="form-value">
											<div className="view-state">
												{definitionControl.userAction === "create" && (
													<TdsTextField
														id="id"
														size="md"
														placeholder={t(
															"Text explaining how to treat the deviation"
														)}
														helper={helperText}
														value={textInputValue}
														state={validationErrorText}
														onTdsChange={(e) =>
															setTextInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "edit" && (
													<TdsTextField
														id="text"
														type="text"
														size="md"
														helper={helperText}
														state={validationErrorText}
														onTdsChange={(e) =>
															setTextInputValue(e.target.value)
														}
														value={textInputValue}
													></TdsTextField>
												)}
												{definitionControl.userAction === "view" && (
													<>
														{
															definitionControl.selectedDefinitionto?.message
																.action.text
														}
													</>
												)}
											</div>
										</div>
									</div>
									<div className="form-field">
										<div className="form-label sdds-headline-07">
											{t("URL")}
										</div>
										<div className="form-value">
											<div className="view-state">
												{definitionControl.userAction === "create" && (
													<TdsTextField
														id="id"
														size="md"
														placeholder={t(
															"Url to the service or other source that might be helpful"
														)}
														helper={helperText}
														value={urlInputValue}
														onTdsChange={(e) =>
															setUrlInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "edit" && (
													<TdsTextField
														id="url"
														type="text"
														size="md"
														helper={helperText}
														value={urlInputValue}
														onTdsChange={(e) =>
															setUrlInputValue(e.target.value)
														}
													></TdsTextField>
												)}
												{definitionControl.userAction === "view" && (
													<>
														{
															definitionControl.selectedDefinitionto?.message
																.action.url
														}
													</>
												)}
											</div>
										</div>
									</div>
									<div className="form-field">
										<div className="form-label sdds-headline-07">
											{t("DoCS Function IDs")}
										</div>
										<div className="form-value">
											<div className="view-state">
												{definitionControl.userAction === "create" &&
													deFunctions.length > 0 && (
														<>
															<sdds-dropdown
																id="multi-select"
																type="multiselect"
																value={selectedValues}
																onTdsChange={() => handleChange}
																placeholder={t("TS_core:SelectFunctions")}
															>
																{deFunctions.map((func) => {
																	return (
																		<sdds-dropdown-option
																			key={func.value}
																			value={func.value}
																		>
																			{func.label}
																		</sdds-dropdown-option>
																	);
																})}
															</sdds-dropdown>
														</>
													)}
												{definitionControl.userAction === "edit" &&
													!definitionControl.deFunctionsApiLoading && (
														<>
															<sdds-dropdown
																id="multi-select"
																type="multiselect"
																value={selectedValues}
																onChange={() => handleChange}
																placeholder={t("TS_core:SelectFunctions")}
															>
																{deFunctions.map((func) => {
																	return (
																		<sdds-dropdown-option
																			key={func.value}
																			value={func.value}
																			selected={selectedValues.includes(
																				func.value
																			)}
																		>
																			{func.label}
																		</sdds-dropdown-option>
																	);
																})}
															</sdds-dropdown>
														</>
													)}
												{definitionControl.userAction === "edit" &&
													definitionControl.deFunctionsApiLoading && (
														<>
															<Spinner size="sm" />
														</>
													)}
												{definitionControl.userAction === "view" && (
													<>
														<div className="functionLineHeight">
															{!definitionControl.deFunctionsApiLoading &&
																((functionIdsToNames
																	? functionIdsToNames
																	: "") ||
																	t("N/A"))}
															{definitionControl.deFunctionsApiLoading && (
																<Spinner size="sm" />
															)}
														</div>
													</>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>

							{definitionControl.userAction !== "view" && (
								<div className="form-bottom-bar">
									<div className="flex">
										<button
											className="sdds-btn sdds-btn-secondary sdds-btn-md action-button"
											title={t("TS_temp:CancelButton")}
											onClick={() => onCancel(definitionControl.userAction)}
										>
											{t("TS_core:Cancel")}
										</button>
										{/* Disabling button is not needed. Should be enabled all the time  */}
										{definitionControl.userAction === "create" &&
											(
												<button
													className="sdds-btn sdds-btn-primary sdds-btn-md action-button"
													title={t("Save button")}
													onClick={() => onSave("create")}
												>
													{t("TS_core:Save")}
												</button>
											)}
										{definitionControl.userAction === "edit" &&
											(
												<button
													className="sdds-btn sdds-btn-primary sdds-btn-md action-button"
													title={t("Update button")}
													onClick={() => onSave("edit")}
												>
													{t("TS_core:Update")}
												</button>
											)}
									</div>
								</div>
							)}
							{showDeleteDialog && (
								<ConfirmationDialog
									title="Confirm delete"
									confirmationMsg="Are you sure you want to delete the registered CSD definition? Once deleted, the definition will no longer be available and delete action cannot be undone."
									onAction={() => {
										onDelete();
										setShowDeleteDialog(false);
									}}
									primaryActionBtn="Delete"
									secondaryActionBtn="Cancel"
									isDelete={true}
									onClose={() => setShowDeleteDialog(false)}
								/>
							)}
							<Toast toastQueue={toastQueue} />
						</div>
					)}
			</div>
		</>
	);
}
export default ViewDefinitionForm;