import React from 'react'

const MoreArrow = () => {
  return (
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.18963 16.8051C0.936792 16.5451 0.936792 16.1237 1.18962 15.8638L7.59139 9.28238C7.74309 9.12643 7.74309 8.87357 7.59139 8.71762L1.18963 2.13622C0.936793 1.8763 0.936793 1.45487 1.18963 1.19494C1.44246 0.935019 1.85238 0.935019 2.10522 1.19494L8.50698 7.77634C9.16434 8.45215 9.16434 9.54785 8.50698 10.2237L2.10521 16.8051C1.85238 17.065 1.44246 17.065 1.18963 16.8051Z" fill="#37414F" stroke="#868FA2" stroke-miterlimit="10" stroke-linecap="round"/>
    </svg>
  )
}
export default MoreArrow
