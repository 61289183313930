import { createSlice } from '@reduxjs/toolkit'
import { getCsdDefinitions, updateCsdDashboardSettings } from '../../adapters/CsdFunctionsAdapter';
import _ from 'lodash';

const getInitialState = (): CsdDefinitionsStateType => {
    const csdDefinitionsInitialState: CsdDefinitionsStateType = {
        isLoading: false,
        hasError: false,
        isSaveSuccess: false,
        errorMessage: "",
        csdsDefinitionsList: [],
        simplifiedCsdDefinitionsList: [],
        userSelectedCsdsDefinitionsList: [],
    };
    return csdDefinitionsInitialState
}
// Slice
const slice = createSlice({
    name: 'csdDefinitions',
    initialState: getInitialState(),
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateCsdDefinitionsList: (state, action) => {
            state.csdsDefinitionsList = action.payload;
        },
        updateSimplifiedCsdDefinitionsList: (state, action) => {
            state.simplifiedCsdDefinitionsList = action.payload;
        },
        updateUserSelectedCsdsDefinitionsList: (state, action) => {
            state.userSelectedCsdsDefinitionsList = action.payload;
        },
        clearUserSelectedCsdsDefinitionsList: (state) => {
            state.userSelectedCsdsDefinitionsList = [];
        },
        updateSaveStatus : (state,action) =>{
          state.isSaveSuccess = action.payload
        },
        updateError: (state, action) => {
            state.hasError = action.payload !== '';
            state.errorMessage = action.payload;
            state.isLoading = false;
        }
    },
});
export default slice.reducer

// Actions
const { setIsLoading, updateCsdDefinitionsList, updateError, updateSimplifiedCsdDefinitionsList, updateUserSelectedCsdsDefinitionsList, clearUserSelectedCsdsDefinitionsList, updateSaveStatus } = slice.actions

export const loadCsdDefinitions = (userSelectedCsds: Array<UserSelectedCds>) => async (dispatch: any, getState: any) => {
    try {
        dispatch(setIsLoading(true));
        const results = await getCsdDefinitions();
        if (results.data) {
            const csdDefinitionsResponse = results.data
            dispatch(updateCsdDefinitionsList(results.data));

            //Preparing custom data structure for popup data communications and based on loggin user configurations checkbox status updating
            const groupedCsdDefinitionListData = _.chain(csdDefinitionsResponse)
            .groupBy('systemName')
            .map((csdDefinition, systemName) => 
            ({
                systemName: systemName,
                isSystemChecked: !!userSelectedCsds.find(({ systemName: sn }) => sn === systemName),
                messageIdList: csdDefinition.map(({ id, message }) => 
                ({ 
                    id: id, 
                    isIdChecked: userSelectedCsds.find(({ systemName: sn }) => sn === systemName)?.ids.includes(id) || false
                }))
            })).value();
            const userSelectedIDList = getSystemwithIDList(groupedCsdDefinitionListData);
            dispatch(updateSimplifiedCsdDefinitionsList(groupedCsdDefinitionListData));
            dispatch(updateUserSelectedCsdsDefinitionsList(userSelectedIDList));
            dispatch(setIsLoading(false));
        }
    } catch (e: any) {
        dispatch(setIsLoading(false));
        dispatch(updateError(e.message));
        return console.error(e);
    }
}

export const UpdateSImpflifiedListData = (userSelectedData: SimplifiedCsdDefinitions[]) => async (dispatch: any) => {
    try {
        dispatch(updateSimplifiedCsdDefinitionsList(userSelectedData));
    } catch (error: any) {
        dispatch(updateError(error.message));
        return console.error(error);
    }
}

export const UpdateUserSelectedCsdData = (userSelectedData: SimplifiedCsdDefinitions[]) => async (dispatch: any) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(clearUserSelectedCsdsDefinitionsList());
      const userSelectedCsdList = getSystemwithIDList(userSelectedData);
      dispatch(updateUserSelectedCsdsDefinitionsList(userSelectedCsdList));
      const csdSettingsRequest = {key : 'csddashboard',
                                value:  JSON.stringify(userSelectedCsdList) } as CsdUserSettings;
      await updateCsdDashboardSettings(csdSettingsRequest);
      dispatch(updateSaveStatus(true));
      dispatch(setIsLoading(false));

    } catch (error: any) {
      dispatch(updateError(error.message));
      dispatch(setIsLoading(false));
      return console.error(error);
    }
}

export const getSystemwithIDList = (userSelectedData: SimplifiedCsdDefinitions[]) => {
  try {
    const userSelectedDataList = userSelectedData.reduce((acc, system) => {
      const checkedIds: any = system.messageIdList
        .filter(message => message.isIdChecked)
        .map(message => ({ systemName: system.systemName, id: message.id }));
      return acc.concat(checkedIds);
    }, []);
    return userSelectedDataList;
  } catch (error: any) {
    console.error(error);
  }   
}

export const resetErrorMessage = () => async (dispatch:any) => {
    try {
      dispatch(updateError(""));
    } catch (error: any) {
      console.error(error);
    }
}

export const resetSaveSuccessStatus = () => async (dispatch:any) => {
  try {
    dispatch(updateSaveStatus(false));
  } catch (error: any) {
    console.error(error);
  }
}
  
