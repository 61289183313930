import { Spinner } from "@cd/sdds-common-components-react"
import { filter } from "lodash"
import { useTranslation } from "react-i18next"

import './system-subscriptions.scss'
import SddsCheckbox from "./SddsCheckbox"
import { ChangeEvent, useState } from "react"

interface SystemSubscriptionPropType {
  hasError?: boolean
  systemErrorEmailIncorrect?: boolean
  systemErrorEmailNotAllowed?: boolean
  isLoading: boolean
  emailAddress: string
  systemSubscriptions: Array<CsdSystemSubscription>
  currentSelectionCount: number
  savedSubscriptionsCount: number
  unSavedChanges: boolean,
  saveSuccess: boolean | null
  onSubscriptionUpdate: (systemName: string, isSubscribed: boolean) => void
  onSave: () => void
  onClear: () => void
}

const SystemSubscription = (props: SystemSubscriptionPropType) => {
  const {
    isLoading,
    hasError,
    systemErrorEmailIncorrect,
    systemErrorEmailNotAllowed,
    emailAddress,
    systemSubscriptions,
    currentSelectionCount,
    savedSubscriptionsCount,
    unSavedChanges,
    saveSuccess,
    onSubscriptionUpdate,
    onSave,
    onClear,
  } = props;

  const [t] = useTranslation();
  const [filterString, setFilterString] = useState<string>('');

  return <div className="csd-system-subscriptions">
    {isLoading && <div className="spin-container"><Spinner size="md"></Spinner></div>}

    <div className="tds-background-grey-100 tds-u-pt3 tds-u-pb3 sticky">
      <div className="">
        <div className="tds-row">
          <div className="tds-col-xlg-8 tds-col-lg-8 tds-col-md-8">
            <div className="sdds-headline-02">{t("TS_core:SystemSubscriptionManagement")} for <span className="tds-text-blue-500">{emailAddress}</span></div>
          </div>
          <div className="tds-col-max-2 tds-col-xxlg-4 tds-col-xlg-4 tds-col-lg-4 tds-col-md-4 tds-col-sm-12 tds-col-xs-12">
            <div className="tds-u-textalign-end sdds-headline-05 tds-text-blue-500">{savedSubscriptionsCount} Saved Subscriptions</div>
          </div>
        </div>
        <div className="tds-row tds-u-mt3">
          <div className="tds-col">
            <div className="sdds-headline-05">{t("TS_core:WhatItMeansToSubscribeTitle")}</div>
            <div className="sdds-body-02">{t("TS_core:WhatItMeansToSubscribeBody")}</div>
          </div>
        </div>
        <div className="toast-div">
          {hasError && <tds-toast
            variant="error"
            header={t("TS_core:SystemErrorFailToLoadData")}
          >
          </tds-toast>}
          {saveSuccess && <tds-toast variant="success" header={t("TS_core:SubscriptionSettingsSaved")}>
          </tds-toast>}
        </div>
      </div>
    </div>
    {(systemErrorEmailIncorrect || systemErrorEmailNotAllowed) && <tds-banner
      variant="error"
      header={t("TS_core:InvalidEmailAddressErrorMessage")}
    >
    </tds-banner>}
    <div className="sdds-u-mt2 scroll-container">
      <div className="tds-container tds-container-fluid zero-left">
        <div className="tds-row tds-u-mr3 sticky selection-container">
          <div className="tds-col-xlg-10 tds-col-lg-8 tds-col-md-8 tds-col-sm-6 tds-col-xs-12 tds-u-align-center">
            <span className="sdds-headline-05">
              {t("CSD System")}
            </span>
            <div className="tds-u-ml3 sdds-chip  sdds-chip__active ">
              <span className="sdds-chip-text">{currentSelectionCount} Selected</span>
            </div>
          </div>
          <div className="tds-col-xlg-2 tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-12">
            <input
              type="search"
              className="sdds-textfield-input sdds-u-float-right"
              placeholder="filter systems by name"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setFilterString(e.target.value)}
            />
          </div>
        </div>
        <div className="tds-row">
          <div className="tds-col-max-12 tds-u-overflow-auto">
            <ul className="subscription-list">
              {systemSubscriptions && filter(systemSubscriptions, (x) => {
                if (filterString !== '') {
                  return x.systemName.toLowerCase().includes(filterString.toLowerCase())
                } else {
                  return true;
                }
              }).map((subscription) => {
                return <li className="tds-u-mt3 tds-u-block" key={subscription.systemName}>

                  <SddsCheckbox
                    id={subscription.systemName}
                    value={subscription.systemName}
                    label={subscription.systemName}
                    checked={subscription.isSubscribed}
                    onClick={(isSubscribed: boolean) => { onSubscriptionUpdate(subscription.systemName, isSubscribed) }}
                  />
                </li>
              })
              }
            </ul>
          </div>
        </div>
       
      </div>
    </div>
    <div className="tds-row sdds-u-mt3 button-container">
          <div className="tds-col-max-2 tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-3 tds-col-md-3 tds-col-sm-4 tds-col-xs-12">
            <button className={`sdds-btn sdds-btn-secondary sdds-btn-fullbleed ${unSavedChanges ? '' : 'disabled'}`} onClick={onClear} disabled={!unSavedChanges} >
              <span>{t("TS_core:Clear")} unsaved</span>
            </button>
          </div>
          <div className="sdds-u-mt1 tds-show-xs" >&nbsp;</div>
          <div className="tds-col-max-2 tds-col-xxlg-2 tds-col-xlg-2 tds-col-lg-2 tds-col-md-2 tds-col-sm-4 tds-col-xs-12">
            <button className={`sdds-btn sdds-btn-primary sdds-btn-fullbleed ${unSavedChanges ? '' : 'disabled'}`} onClick={onSave} disabled={!unSavedChanges}>
              <span>{t("TS_core:Save")}</span>
            </button>
          </div>
        </div>
  </div>
}

export default SystemSubscription;