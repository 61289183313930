import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { createSelectorHook, useDispatch } from 'react-redux'
import { UniversalSearchStore } from '@cd/sdds-common-components-react'

import toastStore from './toastStore'
import positionDelay from './positionDelay'
import csdDefinitions from './CsdDefinitionsStore'
import csdOverwiew from './CsdOverviewStore'
import csdIdSelection from './CsdIdSelectionStore'
import OverviewDetails from './overviewDetailsStore'
import csdAttributes from './CsdAttributesStore'
import csdSystemSubscriptionStore from "./CsdSystemSubscriptionStore";
import allDefinitions from "./DefinitionsStore";
import selectedDateStore from './selectedDateStore'

const reducer = combineReducers({
  toastStore,
  universalSearch: UniversalSearchStore,
  positionDelayStore: positionDelay,
  csdDefinitionsStore: csdDefinitions,
  csdOverwiewStore: csdOverwiew,
  CsdIdSelectionStore: csdIdSelection,
  OverviewDetailsStore: OverviewDetails,
  CsdAttributesStore: csdAttributes,
  csdSystemSubscription:csdSystemSubscriptionStore,
  allDefinitionsStore:allDefinitions,
  selectedDateStore: selectedDateStore
});

const store = configureStore({
  reducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector = createSelectorHook<RootState>();
export default store;