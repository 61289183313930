import React from 'react';
import { CustomModal } from '@cd/sdds-common-components-react';
import './ConfirmationDialog.scss';


type ConfirmationDialogPropsType = {
    onClose: () => void;
    onAction: () => void;
    title: string;
    confirmationMsg: string;
    primaryActionBtn: string;
    secondaryActionBtn: string;
    isDelete?: boolean
};

const ConfirmationDialog = (props: ConfirmationDialogPropsType) => {

    const { title, confirmationMsg, onClose, onAction, primaryActionBtn, secondaryActionBtn, isDelete } = props;
    return (
        <div id='confirmationDialogContainer'>
            <CustomModal
                id="confirmationDialog"
                show={true}
                title={title}
                handleClose={onClose}
                size="xs"
                actions={(
                    <>
                        <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn sdds-u-mb2" onClick={onClose} >{secondaryActionBtn}</button>
                        <button type="button" className={`sdds-btn sdds-btn-sm ${isDelete ? "sdds-btn-danger" : "sdds-btn-primary"} bttn sdds-u-mb2`} onClick={onAction}>{primaryActionBtn}</button>
                    </>
                )}
            >
                <div className="confirmationDialogContent">
                    <span className='sdds-detail-02'>{confirmationMsg}</span>
                </div>
            </CustomModal>
        </div>
    )
}

export default ConfirmationDialog;