import React, { RefObject, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import "@here/maps-api-for-javascript";
import { fullCircleSvg, emptyCircleSvg, halfCircleSvg, crossedCircleSvg, generateClusterPosition } from "./svg"

var COMMUNICATION_HISTORY_URL = 'https://equipment.cd.prod.aws.scania.com/communication/';

var ui: H.ui.UI;
var map: H.Map;

interface EventPropTypes {
    event: Function,
    target: any
};

interface DelayType {
    value: number
    text: string
};

var mapDataPoints: SimplifiedCsdsListInfo[] = [];
var mapDataPointsBuffer: SimplifiedCsdsListInfo[] = [];
var clusteredDataProvider: H.clustering.Provider | null;

var delayTypes: DelayType[] = [
    {
        value: 200,
        text: 'position time to message time'
    },
    {
        value: 100,
        text: 'message time to time saved'
    }];

var csdFilters: number[] = [];
var selectedDelayType = delayTypes[1];

type Props = { PositionList: SimplifiedCsdsListInfo[] }

const PositionDelayMap = ({ PositionList }: Props) => {
    const { t } = useTranslation();
    const mapElement: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

    if (process.env.NODE_ENV === 'development')
        COMMUNICATION_HISTORY_URL = 'https://equipment.cd.devtest.aws.scania.com/communication/';

    function init() {
        initializeHereMaps();
        initializeClusteredMap();
        updateMap(true, true);
    }

    function update() {
        mapDataPointsBuffer = PositionList;
        swapBufferToMap();
    }

    function initializeHereMaps() {
        if (!mapElement.current)
            return;

        // var platform = new window.H.service.Platform({ 'apikey': process.env.hereMapsAPIKey as string });
        const platform = new H.service.Platform({ 'apikey': 'nSODGwb6hqN-hae3-EQcOqPzQbKpwuEZQ3cYxbxgg14' });
        let defaultLayers = platform.createDefaultLayers();

        const vector = Object.getOwnPropertyDescriptor(defaultLayers, 'vector');
        const normal = Object.getOwnPropertyDescriptor(vector?.value, 'normal')
        const mapLayer = Object.getOwnPropertyDescriptor(normal?.value, 'map')

        map = new H.Map(
            mapElement.current,
            mapLayer?.value,
            {
                zoom: 3,
                center: { lng: 39.0, lat: 34.0 }, // Default zoom in södertälje
                pixelRatio: window.devicePixelRatio || 1
            });
        new window.H.mapevents.Behavior(new window.H.mapevents.MapEvents(map));
        ui = window.H.ui.UI.createDefault(map, defaultLayers);
        setupCustomizedMapControl();
    }

    function setupCustomizedMapControl() {
        var customizedMapControl = new window.H.ui.Control();
        var controlPanel = new window.H.ui.base.OverlayPanel();

        var radioGroup = new window.H.ui.base.RadioGroup();
        radioGroup.setTitle(t("TS_core:CalculateDelayFor"));
        radioGroup.addClass('H_rdo_custom_text_labels');

        var onboardButton = new window.H.ui.base.PushButton({
            label: '<strong>' + t("TS_core:Onboard") + '</strong><span>' + t("TS_core:PositionTimeToMessageTime") + '</span>',
            data: 'onboard data',
            onStateChange: function () {
                if (onboardButton.getState() === 'down') {
                    selectedDelayType = delayTypes[0];
                    filterDataPointsOnMap();
                }
            }
        });
        radioGroup.addButton(onboardButton);

        var offboardButton = new window.H.ui.base.PushButton({
            label: t("TS_core:Offboard") + '<span>' + t("TS_core:MessageTimeToTimeSaved") + ' </span>',
            data: 'offboard data',
            onStateChange: function () {
                if (offboardButton.getState() === 'down') {
                    selectedDelayType = delayTypes[1];
                    filterDataPointsOnMap();
                }
            }
        });
        offboardButton.setState(H.ui.base.Button.State.DOWN);
        radioGroup.addButton(offboardButton);

        controlPanel.addChild(radioGroup);
        var menuText = new window.H.ui.base.Element('div', 'H_menu_title');
        menuText.renderInternal = function (element) {
            element.innerHTML = t("TS_core:DisplayDelayLevels");
        };
        controlPanel.addChild(menuText);


        var emptyCircleButton = new window.H.ui.base.PushButton({
            label: '<span class="ctrl-svg-container mr-1">' + emptyCircleSvg() + '</span>10min to 1h',
            data: '',
            onStateChange: function () {
                if (emptyCircleButton.getState() === 'down') {
                    csdFilters.push(1);
                } else {
                    csdFilters.splice(csdFilters.indexOf(1), 1);
                }
                filterDataPointsOnMap();
            }
        });
        emptyCircleButton.setState(H.ui.base.Button.State.DOWN);
        controlPanel.addChild(emptyCircleButton).addClass('H_btn_no_shadow');


        var halfCircleButton = new window.H.ui.base.PushButton({
            label: '<span class="ctrl-svg-container mr-1">' + halfCircleSvg() + '</span>1h to 1 day',
            data: '',
            onStateChange: function () {
                if (halfCircleButton.getState() === 'down') {
                    csdFilters.push(2);
                } else {
                    csdFilters.splice(csdFilters.indexOf(2), 1);
                }
                filterDataPointsOnMap();
            }
        });
        halfCircleButton.setState(H.ui.base.Button.State.DOWN);
        controlPanel.addChild(halfCircleButton).addClass('H_btn_no_shadow');


        var fullCircleButton = new window.H.ui.base.PushButton({
            label: '<span class="ctrl-svg-container mr-1">' + fullCircleSvg() + '</span>1 day to 14 days',
            data: '',
            onStateChange: function () {
                if (fullCircleButton.getState() === 'down') {
                    csdFilters.push(3);
                } else {
                    csdFilters.splice(csdFilters.indexOf(3), 1);
                }
                filterDataPointsOnMap();
            }
        });
        fullCircleButton.setState(H.ui.base.Button.State.DOWN);
        controlPanel.addChild(fullCircleButton).addClass('H_btn_no_shadow');

        var crossedCircleButton = new window.H.ui.base.PushButton({
            label: '<span class="ctrl-svg-container mr-1">' + crossedCircleSvg() + '</span>more than 14 days',
            data: '',
            onStateChange: function () {
                if (crossedCircleButton.getState() === 'down') {
                    csdFilters.push(4);
                } else {
                    csdFilters.splice(csdFilters.indexOf(4), 1);
                }
                filterDataPointsOnMap();
            }
        });
        crossedCircleButton.setState(H.ui.base.Button.State.DOWN);
        controlPanel.addChild(crossedCircleButton).addClass('H_btn_no_shadow');


        var mapControlButton = new window.H.ui.base.PushButton({
            label: '<img src="https://cdn.digitaldesign.scania.com/icons/website_hosted/settings.svg">',
            data: '',
            onStateChange: function () {
                if (mapControlButton.getState() === 'down') {
                    controlPanel.pointToControl(customizedMapControl);
                    controlPanel.setState(H.ui.base.OverlayPanel.State.OPEN);
                } else {
                    controlPanel.setState(H.ui.base.OverlayPanel.State.CLOSED);
                }
                filterDataPointsOnMap();
            }
        });
        customizedMapControl.addChild(mapControlButton);
        customizedMapControl.addChild(controlPanel);
        customizedMapControl.setAlignment(H.ui.LayoutAlignment.TOP_RIGHT);
        ui.addControl('customizedMapControl', customizedMapControl);
    }

    function swapBufferToMap() {
        let filteredCsds = getCsdsFiltered(mapDataPointsBuffer);
        addClusterDataPointsByCsdObjects(filteredCsds);
        mapDataPoints = mapDataPoints.concat(mapDataPointsBuffer);
        mapDataPointsBuffer = [];
    }

    function getCsdsFiltered(csds: SimplifiedCsdsListInfo[]) {
        var activeCsdCodes = csdFilters.map((filter: number) => selectedDelayType.value + filter);
        return csds.filter((csd: SimplifiedCsdsListInfo) => activeCsdCodes.includes(csd.definitionId));
    };


    function addClusterDataPointsByCsdObjects(csdObjects: SimplifiedCsdsListInfo[]) {
        clusteredDataProvider?.addDataPoints(csdObjects.map(function (item: SimplifiedCsdsListInfo) {
            return new H.clustering.DataPoint(Number(item.latitude), Number(item.longitude), undefined, item);
        }));
    }

    function initializeClusteredMap() {
        clusteredDataProvider = new window.H.clustering.Provider([], {
            min: 1,
            max: 17,
            clusteringOptions: {
                eps: 64,
                minWeight: 2,
                strategy: window.H.clustering.Provider.Strategy.FASTGRID
            }
        });

        var defaultTheme = clusteredDataProvider.getTheme(),
            mapTheme = {
                getClusterPresentation: function (cluster: H.clustering.ICluster) {
                    var options: H.map.Marker.Options = {
                        data: undefined,
                        icon: new window.H.map.Icon(generateClusterPosition(cluster.getWeight()), { anchor: { x: 10, y: 10 } }),
                        min: cluster.getMinZoom(),
                        max: cluster.getMaxZoom()
                    }
                    return new window.H.map.Marker(cluster.getPosition(), options);
                },
                getNoisePresentation: function (noisePoint: H.clustering.INoisePoint) {
                    var noiseMarker: any = defaultTheme.getNoisePresentation.call(defaultTheme, noisePoint),
                        data = noisePoint.getData(),
                        codeEnding = data.definitionId - selectedDelayType.value,
                        svg: string | HTMLImageElement | HTMLCanvasElement = '';

                    switch (codeEnding) {
                        case 1:
                            svg = emptyCircleSvg();
                            break;
                        case 2:
                            svg = halfCircleSvg();
                            break;
                        case 3:
                            svg = fullCircleSvg();
                            break;
                        case 4:
                            svg = crossedCircleSvg();
                    }

                    noiseMarker.setIcon(new window.H.map.Icon(svg, { anchor: { x: 10, y: 10 } }));
                    return noiseMarker;
                }
            };

        clusteredDataProvider.setTheme(mapTheme);
        clusteredDataProvider.addEventListener('tap', onMarkerClick);
        var layer = new window.H.map.layer.ObjectLayer(clusteredDataProvider);
        map.addLayer(layer);
    }

    function filterDataPointsOnMap() {
        setClusterDataPointsByCsdObjects(getCsdsFiltered(mapDataPoints));
    }

    function setClusterDataPointsByCsdObjects(csdObjects: SimplifiedCsdsListInfo[]) {
        if (csdObjects === undefined) return;
        if (!clusteredDataProvider) return;
        clusteredDataProvider.setDataPoints(csdObjects.map(function (item: SimplifiedCsdsListInfo) {
            return new window.H.clustering.DataPoint(Number(item.latitude), Number(item.longitude), undefined, item);
        }));
    }

    function onMarkerClick(e: EventPropTypes) {
        var data = e.target.getData();
        if (data === undefined) return;

        var bubbles = ui.getBubbles();
        if (bubbles.length > 0) {
            bubbles[0].close()
            bubbles.pop();
        }

        var position = e.target.getGeometry();
        var tooltipContent = '<strong>Ext. equipment ID: </strong><br>' + data.a.data.equipment + '<br>' +
            '<br><a class="map-tooltip-link" target="_blank" href=" ' + COMMUNICATION_HISTORY_URL + data.a.data.equipment + '/communication-history"><img class="map-tooltip-redirect" src="https://cdn.digitaldesign.scania.com/icons/website_hosted/redirect.svg"></img>Communication History</a>';
        var tooltip = new window.H.ui.InfoBubble(position, {
            content: tooltipContent
        });
        tooltip.setPosition(position);
        tooltip.addClass('here-map-tooltip');
        tooltip.open();
        ui.addBubble(tooltip);
        map.setCenter(position, true);
    }

    function updateMap(reCenterMap: boolean, newSearch: boolean) {
        if (newSearch) {
            // clear map
            clusteredDataProvider?.setDataPoints([]);
        }

        update();
        map.zoomAt(3, 0.0, 0.0);
        map.setCenter({ lng: 39.0, lat: 34.0 });
    }

    useEffect(() => {
        if (!mapElement.current)
            return;
        init();
    }, []);

    useEffect(() => {
        if (PositionList.length > 0)
            updateMap(true, true);
    }, [PositionList]);


    return (
        <>
            <div className="map-control" ref={mapElement} />
        </>
    )
};


export default PositionDelayMap;