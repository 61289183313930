import { createSlice } from '@reduxjs/toolkit'
import { getCsdOverviewV2 } from '../../adapters/CsdFunctionsAdapter';

const getInitialState = (): CsdOverviewStateType => {
    const csdDefinitionsInitialState: CsdOverviewStateType = {
        onStoreLoad:true,
        isLoading: false,
        hasError: false,
        errorMessage: "",        
        paginationToken: undefined,
        isDone: false,
        csdsOverviewList: [],
        selectedCsds:['']
    };
    return csdDefinitionsInitialState
}
// Slice
const slice = createSlice({
    name: 'csdOverwiew',
    initialState: getInitialState(),
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setOnStoreLoad: (state, action) => {
            state.onStoreLoad = action.payload;
        },
        updateCsdOverviewList: (state, action) => {
            const { isDone, paginationToken } = action.payload;
            state.isDone = isDone;
            state.paginationToken = paginationToken;
            state.csdsOverviewList = state.csdsOverviewList.concat(action.payload);
        },
        clearCsdOverviewList: (state, action) => {
            state.isDone = action.payload.isDone;
            state.paginationToken = action.payload.paginationToken;
            state.csdsOverviewList = action.payload.csdsList;
        },        
        updateSelectedCsds:(state, action) => {
            state.selectedCsds = action.payload;            
        },
           
        updateError: (state, action) => {
            state.hasError = action.payload !== '';
            state.errorMessage = action.payload;
            state.isLoading = false;
        },
    },
});
export default slice.reducer

// Actions
const { setIsLoading, setOnStoreLoad, updateCsdOverviewList, clearCsdOverviewList, updateError, updateSelectedCsds } = slice.actions

export const loadCsdDefinitionsOverview = (startTime: string, endTime: string, paginationToken = "", systemName: String, idsList: Array<number>) => async (dispatch: any, getState: any) => {
    try {
        const request = //Created for Test purpose. But can also be used as solution.
            {
                startTime: startTime,
                endTime: endTime,
                csdCount: 0,
                paginationToken: paginationToken ? paginationToken : undefined,
                ids: [...idsList],
                systemName: systemName,
                productEntities: [],
                users: [],
                details: []
            } as CsdOverviewRequest;
        dispatch(clearCsdOverviewList({ csdsList: [], isDone: false, paginationToken: undefined }));
        dispatch(setIsLoading(true));
        const results = await getCsdOverviewV2(request);
        if (results.data) {
            dispatch(updateCsdOverviewList(results.data));
            dispatch(setIsLoading(false));
        }
    } catch (e: any) {
        dispatch(setIsLoading(false));
        dispatch(updateError(e.message));
        return console.error(e);
    }
}

export const clearCdsOverviewDataList = () => async (dispatch: any) => {
    try {
      dispatch(clearCsdOverviewList({ csdsList: [], isDone: false, paginationToken: undefined }));
    } catch (error: any) {
      dispatch(updateError(error.message));
      return console.error(error);
    }
  } 

  export const updateDashboardSelections = (selectedCsds:Array<UserSelectedCds>)=> async (dispatch: any) => {

    try {        
        dispatch(updateSelectedCsds(selectedCsds));
      } catch (error: any) {
        dispatch(updateError(error.message));
        return console.error(error);
      }

  }

  export const updateOnFirstLoad = (onFirstLoad:boolean)=> async (dispatch: any) => {

    try {        
        dispatch(setOnStoreLoad(onFirstLoad));
      } catch (error: any) {
        dispatch(updateError(error.message));
        return console.error(error);
      }

  }
