
import React, { useState, useEffect } from "react";
import { DataTable, DataCell, DataRow, Spinner } from "@cd/sdds-common-components-react";

import { t } from "i18next";
import DateTimeSeperator from "../DateTimeSeperator";


interface CsdDataTablePropType {
  tableData: CsdIdTimeType[]
  isLoading: boolean
  onSelectCsd: (csd: any) => void
  activeRowIndex:number
  setActiveRowIndex:Function
}

interface ColumnConfigType {
  columnid: string
  title: string
  sortable: boolean
  freeze?: boolean
  colSpan?: number
  removeable?: boolean
}

export const MessagesDataTable = ({ tableData, isLoading, onSelectCsd, activeRowIndex, setActiveRowIndex }: CsdDataTablePropType) => {

  const [data, setData] = useState<Array<CsdIdTimeType>>([]);
  const columnsConfig: Array<ColumnConfigType> = [
    {
      columnid: "definitionId",
      title: t("Definition Id"),
      sortable: false
    },
    {
      columnid: "time",
      title: t("TS_core:CreatedAt"),
      sortable: false
    },
  ];

  useEffect(() => {
    setData(tableData)
  }, [tableData])

  const onRowClick = (csd:CsdIdTimeType, rowIndex:number) =>{
    onSelectCsd(csd);
    setActiveRowIndex(rowIndex);
  }

  return <div className="cd-table-wrapper">
    <DataTable
      title={""}
      columns={columnsConfig}
    >
      {data && data.length > 0 && data.map((row: any, index) => {
        return <>
          <div className={`${index === activeRowIndex ? 'selected-row':''} `} role="button" onKeyDown={() => { }} onClick={() => onRowClick(row, index)}>
            <DataRow>
              {columnsConfig?.map((col) => {
                switch (col.columnid) {
                  case 'definitionId':
                    return <DataCell cssClassName="freeze">{row[col.columnid]}</DataCell>
                  default:
                    return <DataCell cssClassName="freeze"><DateTimeSeperator inputDateTime={row[col.columnid]} /></DataCell>
                }
              })}
            </DataRow>
          </div>
        </>
      })}
      {(!data || data.length === 0) && isLoading &&
        <DataRow>
          <DataCell colSpan={2}><Spinner size="md" /></DataCell>
        </DataRow>
      }
      {(!data || data.length === 0) && !isLoading &&
        <DataRow>
          <DataCell colSpan={2}>{t("TS_core:NoDataFound")}</DataCell>
        </DataRow>
      }
    </DataTable>
  </div>
}

export default MessagesDataTable;