import _ from "lodash";

const replacePlaceholders = (text: string, mutations: { placeholder: string, replacement: string }[]) : string => {
  let mutatedText = text;
  mutations.forEach(m => {
    mutatedText = mutatedText.replace(m.placeholder, m.replacement);
  });
  return mutatedText;
};

const createArrysOfIdsBySystemNames  = (csdDashboardSettings: string) : Array<any> =>{
  //let CsdObjects = convertCsdDashBoardSettingsToArrayOfObjects(csdDashboardSettings);
  let CsdObjects = JSON.parse(csdDashboardSettings);
  if(CsdObjects.length === 0){
    return CsdObjects;
  }
  // initiatal state
  let currentSystem = CsdObjects[0].systemName;
  let systemIds = [{ systemName: currentSystem, ids: [CsdObjects[0].id] }];
  let index = 0;
  _.forEach(CsdObjects ,s => {
    if(s.systemName === currentSystem){
      if(!systemIds[index].ids.includes(s.id))
        systemIds[index].ids.push(s.id);
    }
    else{
      systemIds.push({ systemName: s.systemName,  ids: [s.id]})
      currentSystem = s.systemName;
      index++;
    }
  });
  return systemIds;
}

export default replacePlaceholders;

export {
  replacePlaceholders,
  createArrysOfIdsBySystemNames
};
