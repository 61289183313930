import { createSlice } from '@reduxjs/toolkit'
import { fetchCsdsStreamData } from '../../adapters/PositionDelayAdapter';

const getInitialState = (): PositionDelayStateType => {
  const State: PositionDelayStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    searchInput: undefined,
    paginationToken: undefined,
    isDone: false,
    csdsList: []
  }
  return State;
}

// Slice
const slice = createSlice({
  name: 'positionDelay',
  initialState: getInitialState(),
  reducers: {
    setStartActionLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    updateCsdList: (state, action) => {
      const { csds, isDone, paginationToken } = action.payload;
      state.isDone = isDone;
      state.paginationToken = paginationToken;
      state.csdsList = state.csdsList.concat(csds);      
    },
    clearCsdList: (state, action) => {
      state.isDone = false;
      state.paginationToken = undefined;
      state.csdsList = [];
    },
    updateError: (state, action) => {
      state.hasError = action.payload !== '';
      state.errorMessage = action.payload;
      state.isLoading = false;
    }
  },
});
export default slice.reducer

// Actions
const { setStartActionLoader, updateCsdList, updateError, clearCsdList } = slice.actions

export const loadCsds = (startTime: string, endTime: string, paginationToken: string) => async (dispatch: any, getState: any) => {
  try {
    const systemName = "Position Streaming Data Monitor";    
    dispatch(setStartActionLoader(true));
    const csdResponseType = await fetchCsdsStreamData({ startTime: startTime, endTime: endTime, paginationToken: paginationToken ? paginationToken : undefined }, systemName);

    let csdsDataResponse = csdResponseType.data

    let simplefiedCsdList: Array<SimplifiedCsdsListInfo> = csdsDataResponse.csds.map((csd: any) => ({
      id: csd.id,
      definitionId: csd.definitionId,
      equipment: getPropertyByType(csd.productEntity, 'ExternalEquipmentReference'),
      latitude: getPropertyByType(csd.geoLocation, 'Latitude'),
      longitude: getPropertyByType(csd.geoLocation, 'Longitude')
    }));
    
    dispatch(updateCsdList({ csds: simplefiedCsdList, isDone: csdsDataResponse.isDone, paginationToken: csdsDataResponse.paginationToken }));   
    dispatch(setStartActionLoader(false));
  } catch (error: any) {
    dispatch(updateError(error.message));
    dispatch(setStartActionLoader(false));
    return console.error(error);
  }
}

export const clearCsdData = () => async (dispatch: any) => {
  try {
    dispatch(clearCsdList({ csds: [], isDone: false, paginationToken: undefined }));

  } catch (error: any) {
    dispatch(updateError(error.message));
    return console.error(error);
  }
}

export const getPropertyByType = (entityArray: any, type: any) => {
  const propObj = entityArray.find((entity: any) => entity.type === type);
  return propObj ? propObj.value : null;
}


