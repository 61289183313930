import React, { useState, useEffect } from 'react';
import { useIsAuthenticated, AuthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { InteractionType } from "@azure/msal-browser";
import './App.css';
import { callCsttFacadeApis } from './adapters/UserAdapter';
import { loginRequest } from './authConfig';
import { Spinner, UserSettings, useToast, Toast, MOpinionFeedback } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';
import { RBACProvider, RBACWrapper } from 'react-simple-rbac';
import { UserContext } from './appContext';
import { useSelector } from 'react-redux';

import Index from './pages';
import DashboardPage from './pages/Dashboard/dashboardPage';
import CsdDefinitionsPage from './pages/CsdDefinitions/csdDefinitionsPage';
import CsdSystemSubscriptionPage from './pages/CsdSystemSubscription/csdSystemSubscriptionPage';
import PositionDelayPage from './pages/PositionDelay/positionDelayPage';


const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const [loggedInProfileInfo, setLoggedInProfileInfo] = useState<ProfileInformationType>({
    "firstName": "",
    "lastName": "",
    "email": "",
    "role": "",
    "permissions": {
      "developer": false
    },
    "settings": {
      "csddashboard": "",
      "unit": "",
      "language": "en-GB"
    },
    "scaniaId": "",
    "impersonationAccess": false
  });

  const { i18n } = useTranslation();
  const [apiResponse, setApiResponse] = useState({});
  const [apiCalled, setApiCalled] = useState<string>('getProfileInfo');
  const [isDeveloper, setIsDeveloper] = useState<boolean>(false);

  const [profileInfoLoader, setProfileInfoLoader] = useState<AsyncStateType>({ loading: true, error: false });

  const { toastQueue, addToast } = useToast()
  const { toastStore }: any = useSelector(state => state)
  useEffect(() => {
    if (isAuthenticated) {
      setApiCalled('getProfileInfo');
      callCsttFacadeApis(apiCalled, setApiResponse, setProfileInfoLoader, []);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (profileInfoLoader.error) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload();
    }
  }, [profileInfoLoader]);

  useEffect(() => {
    setLoggedInProfileInfo(apiResponse as ProfileInformationType)
  }, [apiResponse]);

  useEffect(() => {
    i18n.changeLanguage(loggedInProfileInfo?.settings?.language || 'en-GB');
    if (loggedInProfileInfo.role === 'Admin' && loggedInProfileInfo.permissions?.developer) { // to enable special permissions for developers
      setIsDeveloper(true)
    } else
      setIsDeveloper(false)
  }, [loggedInProfileInfo, i18n]);

  useEffect(() => {
    if (toastStore.toastMessage !== '') {
      addToast({
        title: toastStore.toastMessage.title, text: toastStore.toastMessage.text,
        type: toastStore.toastMessage.type
      });
    }
  }, [toastStore.toastMessage]);

  return (
    <>
      <div className="App">
        <sdds-theme global="true" />
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
        >
        </MsalAuthenticationTemplate>
        <AuthenticatedTemplate>
        {profileInfoLoader.loading && (<div className='tds-u-mt3'><Spinner size='lg' /></div>)}
          {(!(profileInfoLoader.error || profileInfoLoader.loading)) && (
            <RBACProvider roles={[loggedInProfileInfo.role]} >
              <UserContext.Provider value={loggedInProfileInfo}>
                <Router >
                  <Routes>
                    <Route index element={<Navigate to="/overview" replace={true} />} />
                    <Route path="/" element={<Index />}>
                      <Route path='overview' element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}><DashboardPage csddashboard = { loggedInProfileInfo.settings?.csddashboard ? loggedInProfileInfo.settings?.csddashboard: "[]" } /></RBACWrapper></>} />
                      <Route path="csddefinitions" element={<><RBACWrapper oneOf requiredRoles={["Admin"]}><CsdDefinitionsPage developerAccess={isDeveloper} /></RBACWrapper></>} />
                      <Route path="csdsystemsubscription" element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}><CsdSystemSubscriptionPage /></RBACWrapper></>} />
                      <Route path="positiondatadelay" element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}><PositionDelayPage /></RBACWrapper></>} />
                    </Route>
                    <Route path="/user-settings" element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}><UserSettings
                      userDetails={loggedInProfileInfo}
                      setLoggedInProfileInfo={setLoggedInProfileInfo}
                    /></RBACWrapper></>} />
                  </Routes>
                </Router>
              </UserContext.Provider>
            </RBACProvider>)}
          {profileInfoLoader.error && (<p>{'Profile Info Api : ' + profileInfoLoader.errorMessage}</p>)}
        </AuthenticatedTemplate>
        {<Toast toastQueue={toastQueue} />}
        <MOpinionFeedback />
      </div>
    </>
  );
};

export default App;
