import React, { useEffect, useState } from "react"
import { clearCsdData, loadCsds } from "../../redux/store/positionDelay";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useTranslation } from "react-i18next"
import { InfoIcon } from "@cd/sdds-common-components-react/lib/esm/components/assets"
import SearchAndFilter from "../../components/SearchAndFilter"
import PositionDelayMap from "../../components/PositionDelayMap";
import "./PositionDelay.scss"
import Spinner from "@cd/sdds-common-components-react/lib/esm/components/Spinner";


const PositionDelayPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { csdsList, isLoading, paginationToken} = useAppSelector<PositionDelayStateType>(state => state.positionDelayStore);

    const [fromDate, setFromDate] = useState<string>();
    const [toDate, setToDate] = useState<string>();

    const handleOnSearch = (fromDate: string, toDate: string) => {
        setFromDate(fromDate);
        setToDate(toDate);
        dispatch(clearCsdData());
        dispatch(loadCsds(fromDate, toDate, '{}'));   
    }

    const handleLoadMoreMessages = () => {
        if (paginationToken !== '') {
            dispatch(loadCsds(fromDate? fromDate : '', toDate ? toDate : '', paginationToken ? paginationToken : ''));
        }
    }

    var positionDelays: SimplifiedCsdsListInfo[] = [];

    useEffect(() => {
        positionDelays = [];
        csdsList.map((positionDelay) => {
            var convertedtPositionDelay: SimplifiedCsdsListInfo = {
                id: positionDelay.id,
                definitionId: positionDelay.definitionId,
                equipment: positionDelay.equipment,
                latitude: positionDelay.latitude,
                longitude: positionDelay.longitude
            }
            positionDelays.push(convertedtPositionDelay);
            return positionDelays;
        });

    }, [csdsList]);

    return <>
        <div className="search-position-data-delay">
            <div className="search-wrapper">
                <div className="sdds-col-xs-12 sdds-col-sm-12">
                    <div className="sdds-row space-between">
                        <div className="sdds-headline-05">{t("TS_core:PositionDataDelay")} </div>
                        <div className='flex'><InfoIcon />{t("TS_core:AllTimeIsUTCTime")}</div>
                    </div>
                    <div className="sdds-row"><SearchAndFilter onSearch={handleOnSearch} /></div>                  
                </div>
            </div>
            <div className="sdds-row message-filters">
                <div className='sdds-u-mr2 sdds-headline-05 csd-number'>{csdsList.length}{t(" CSDs loaded")}</div>
                {(csdsList.length < 1 && (!isLoading)) ? '' : <>{isLoading ? isLoading && <Spinner size='sm' /> :
                    <button type="button" className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn" disabled={paginationToken === '{}'} onClick={handleLoadMoreMessages}>{t("Load more CSDs")}</button>}</>}
            </div>
            <div className="sdds-col-xs-12 sdds-col-sm-12">
                <div className="map-container">
                    <PositionDelayMap PositionList={csdsList} />
                </div>
            </div>
        </div>
    </>
}

export default PositionDelayPage;
