import React, { useRef, useState } from "react";
import { Icons } from "@cd/sdds-common-components-react";
import { useAppDispatch } from "../../../redux/store";
import "./accordion.scss"
import { setSelectedCsdId } from "../../../redux/store/CsdIdSelectionStore";


const AccordionItem = (props: OverviewDataType) => {
    const contentEl = useRef<HTMLInputElement>(null);
    const {systemName, csdCount, productEntities, users, details, accordionIndex, selectedAccordion, setSelectedAccordion} = props;
    const [active, setActive] = useState<string>("");
    const dispatch = useAppDispatch();
    const [activeRowIndex, setActiveRowIndex] = useState(-1); 

    const handleToggle = (index: string) => {
        if (active === index) {
            setActive("");
        } else {
            setActive(index);
        }
    }

    const handleCsdIdSelection = (systemName: string, csdId: number, index:number, accordionIndex:number) => {
        // Save in store
        dispatch(setSelectedCsdId(systemName, csdId));  

        setActiveRowIndex(index);
        setSelectedAccordion(accordionIndex)        
    };

    return (<>
        <div className="accordion-wrapper">
            <div className="rc-accordion-header" id={systemName}>
                <div className={`rc-accordion-toggle p-3 ${active === systemName ? 'active' : ''}`} onClick={() => handleToggle(systemName)} aria-hidden="true" >

                    <div className="tds-headline-07">{systemName}</div>
                    <div className="rc-accordion-right total">
                        <span>
                            <Icons.WarningSvg />
                            <span>{csdCount}</span>
                        </span>
                        <span>
                            <Icons.TruckSvg />
                            <span>{productEntities?.length}</span>
                        </span>
                        <span>
                            <Icons.CustomerSvg />
                            <span>{users?.length}</span>
                        </span>              
                    </div>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === systemName ? 'show' : ''}`} style={
                active === systemName
                    ? { height: contentEl.current?.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    {
                        details && details.map((detail, index) => {
                            return (
                                <div key={index}><span className="" role="button" onClick={() => handleCsdIdSelection(systemName, detail.definitionId, index, accordionIndex)} onKeyDown={() => { }}>
                                    <div className={`${index + ':' + accordionIndex === activeRowIndex + ':' + selectedAccordion ? 'selected-row':''} rc-accordion-row`} role="button" id={detail.definitionId.toString()}>
                                        <div className="rc-accordion-title">{detail.definitionId}</div>
                                        <div className={`rc-accordion-right ${detail.csdCount === 0 && detail.productEntities.length === 0 && detail.users.length === 0? 'greyed-out':''}`}>
                                            <span>
                                                <Icons.WarningSvg />
                                                <span>{detail.csdCount}</span>
                                            </span>

                                            <span>
                                                <Icons.TruckSvg />
                                                <span>{detail.productEntities.length}</span>
                                            </span>

                                            <span>
                                                <Icons.CustomerSvg />
                                                <span>{detail.users.length}</span>
                                            </span>
                                        </div>
                                    </div>
                                </span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div >
    </>
    )
}

export default AccordionItem;