import moment from "moment";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';

dayjs.extend(utc);

const initialSearchDate = () => {
  let currentDate = Date.now();
  let startTime = moment.utc(currentDate).subtract(24, 'hours').startOf('minute').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  let endTime = moment.utc(currentDate).startOf('minute').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  return { startTime, endTime };
};

const utcFormatFromString = (datetime: string): string => {
  if (datetime === 'now') {
    return dayjs.utc().format('YYYY-MM-DD HH:mm:ss');
  }
  return datetime ? dayjs.utc(datetime).format('YYYY-MM-DD HH:mm:ss') : '-';
};

const utcFormatFromDate = (datetime: Date | string): string => dayjs(datetime).utc().format('YYYY-MM-DD HH:mm:ss');

const isoFormatFromDate = (datetime: Date | string): string => dayjs(datetime).toISOString();

const utcFormatFromStringDateTime = (datetime: string): { utcDate: string, utcTime: string } => {
  let utcDate: string, utcTime: string;
  if (datetime === 'now') {
    utcDate = dayjs.utc().format('YYYY-MM-DD');
    utcTime = dayjs.utc().format('HH:mm:ss');
  }
  utcDate = datetime ? dayjs.utc(datetime).format('YYYY-MM-DD') : '';
  utcTime = datetime ? dayjs.utc(datetime).format('HH:mm:ss') : '';
  return { utcDate, utcTime };
};

const durationFormat = (formatedObj: customDateFormater): string => {
  const formatedValue = _.keys(formatedObj).map((key) => {
    if (formatedObj[key] !== -0) {
      return formatedObj[key] + key
    }
    return ""
  }).join(" ");
  return formatedValue;
}

const exportExcelUtcFormatFromString = (datetime: any): string => {
  if (datetime === 'now') {
    return dayjs.utc().format('YYYYMMDDHHmm');
  }
  return datetime ? dayjs.utc(datetime).format('YYYYMMDDHHmm') : '-';
};

export {
  utcFormatFromString,
  utcFormatFromDate,
  isoFormatFromDate,
  utcFormatFromStringDateTime,
  durationFormat,
  exportExcelUtcFormatFromString,
  initialSearchDate
};