import { Header, NavLink, NoRoleDisclaimer } from "@cd/sdds-common-components-react"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import { RBACWrapper } from "react-simple-rbac"
import { UserContext } from "../appContext"


const Index = () => {

  const { t } = useTranslation();
  const loggedInProfileInfo = useContext<ProfileInformationType>(UserContext);
  
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchResult, setSearchResult] = useState<string>('');

  const currentApp = 'CSD';


  return (
    <>
    {(!loggedInProfileInfo.role || loggedInProfileInfo.role === null) ? (
          <>
          <NoRoleDisclaimer />
          </>
        ) : 
       (
      <>
      <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
      <Header
        profileInfo={loggedInProfileInfo}
        currentApp={currentApp}
        setSearchComplete={setSearchStatus}
        setSearchStatus={setSearchResult}
        equipmentRedirectPath={''}
      >
        <RBACWrapper oneOf requiredRoles={["Admin", "GTS",  "FQ"]}>
          <NavLink path="/overview" text={t("TS_core:CsdDashboard")} idd="dashboard_Tour_Id" />
        </RBACWrapper>
        <RBACWrapper oneOf requiredRoles={["Admin"]}>
          <NavLink path="/csddefinitions" text={t("TS_core:CsdDefinitions")} uniqueId={"csd-definitions"} idd="csd-definition_Tour_Id" />
        </RBACWrapper>
        <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
          <NavLink path="/csdsystemsubscription" text={t("TS_core:SystemSubscriptionManagement")} idd={"EquipmentSatchSearch_Tout_id"} />
        </RBACWrapper>
        <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
          <NavLink path="/positiondatadelay" text={t("TS_core:PositionDataDelay")} idd={"EquipmentSatchSearch_Tout_id"} />
        </RBACWrapper>
      </Header>
      </RBACWrapper>
      <RBACWrapper oneOf requiredRoles={[ "LoTH", "Basic","BasicLimited","Assistance"]}>
        <tds-banner 
          icon="error" 
          variant="error"
          header="Access Denied" 
          subheader="You need an Admin, FQ or GTS role to access this page."> 
        </tds-banner>
      </RBACWrapper>
      <Outlet />
      </>
      )}
    </>

  )
}

export default Index