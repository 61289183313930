import React from 'react'
import { Icons } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';

import "./recordedImpacts.scss"
import DateTimeSeperator from '../DateTimeSeperator';


interface CsdDetailsPropType {
  onClose: () => void
  impactList: string[]
}

const RecordedImpacts = ({ onClose, impactList }: CsdDetailsPropType) => {
  const { t } = useTranslation()
  return <div className='records-wrapper'>
    <div className='records-header'>
      <span className='sdds-headline-05'>{t("Recorded Impacts")}</span>
      <button onClick={onClose}><Icons.CrossIcon /></button>
    </div>
    <div className='records-panel'>
      {
        impactList.map((c) => {
          return (<span><DateTimeSeperator inputDateTime={c} /></span>);
        })
      }
    </div>
  </div>
}

export default RecordedImpacts