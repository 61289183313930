import { createSlice } from '@reduxjs/toolkit'
import { fetchUserSystemSubscriptions, postUserSystemSubscriptions } from '../../adapters/CsdStatisticsAdapter';
import { orderBy } from 'lodash';

const getInitialState = (): CsdSystemSubscriptionStateType => {
  const initialState: CsdSystemSubscriptionStateType = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
    systemSubscriptions: [],
    saveSuccess:null
  };
  return initialState
}
// Slice
const slice = createSlice({
  name: 'csdS',
  initialState: getInitialState(),
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updateError: (state, action) => {
      state.errorMessage = action.payload;
      state.hasError = action.payload && action.payload !== ''
    },
    updateSystemSubscriptions: (state, action) => {
      state.systemSubscriptions = action.payload;
    },
    clearState: (state, action) => {
      state = action.payload
    },
    updateSaveSuccess : (state,action) =>{
      state.saveSuccess = action.payload
    }
  }
});

export default slice.reducer;

// Actions
const { setIsLoading, updateSystemSubscriptions, clearState, updateError,updateSaveSuccess } = slice.actions

export const getSystemSubscriptions = () => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    const systemSubscriptionsResponse = await fetchUserSystemSubscriptions();
    let sortedData = orderBy(systemSubscriptionsResponse.data, [subs => subs.systemName.toLowerCase()], ['asc']);
    dispatch(updateSystemSubscriptions(sortedData));
    dispatch(setIsLoading(false));
  } catch (error: any) {
    dispatch(setIsLoading(false));
    console.error(error);
  }
}
export const saveSystemSubscriptions = (systemSubscriptions: Array<CsdSystemSubscription>) => async (dispatch: any) => {
  try {
    dispatch(setIsLoading(true));
    await postUserSystemSubscriptions(systemSubscriptions);
    dispatch(updateSaveSuccess(true));
    const systemSubscriptionsResponse = await fetchUserSystemSubscriptions();
    let sortedData = orderBy(systemSubscriptionsResponse.data, [subs => subs.systemName.toLowerCase()], ['asc']);
    dispatch(updateSystemSubscriptions(sortedData));
    dispatch(setIsLoading(false));
  } catch (error: any) {
    dispatch(setIsLoading(false));
    dispatch(updateError(error))
    console.error(error);
  }
}

export const clearStore = () => async (dispatch: any) => {
  try {
    dispatch(clearState(getInitialState()));
  } catch (error: any) {
    console.error(error);
  }
}

export const resetSaveSuccess = () => async (dispatch:any) => {
  try {
    dispatch(updateSaveSuccess(false));
  } catch (error: any) {
    console.error(error);
  }
}
export const resetHasError = () => async (dispatch:any) => {
  try {
    dispatch(updateError(""));
  } catch (error: any) {
    console.error(error);
  }
}