import { createSlice } from '@reduxjs/toolkit'
import { getCsdWithAttributes } from '../../adapters/CsdFunctionsAdapter';

const getInitialState = (): CsdAttributeStateType => {
    const csdDefinitionsInitialState: CsdAttributeStateType = {
        attributes: {
            id: '',
            version: '',
            systemName: '',
            definitionId: 0,
            validationTime: '',
            creationTime: '',
            productEntity: [],
            user: [],
            correlationIds: [],
            faultValue: null,
            logEntryId: '',
        },
        isLoading: false,
        hasError: false,
        errorMessage: ''
    };
    return csdDefinitionsInitialState
}
// Slice
const slice = createSlice({
    name: 'csdAttributes',
    initialState: getInitialState(),
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateCsdAttributes: (state, action) => {
            state.attributes = action.payload;
        },
        updateError: (state, action) => {
            state.hasError = action.payload !== '';
            state.errorMessage = action.payload;
            state.isLoading = false;
        }
    },
});
export default slice.reducer

// Actions
const { setIsLoading, updateCsdAttributes, updateError } = slice.actions

export const loadCsdAttributes = (csdId: string) => async (dispatch: any) => {
    try {
        dispatch(setIsLoading(true));
        const results = await getCsdWithAttributes(csdId);
        if (results.data) {
            dispatch(updateCsdAttributes(results.data))
            dispatch(setIsLoading(false));
        }
    } catch (e: any) {
        dispatch(setIsLoading(false));
        dispatch(updateError(e.message));
    }
}

