import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomModal, Spinner, Toast, useToast } from "@cd/sdds-common-components-react";
import "./csdDefinitionRowDialog.scss"
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { UpdateSImpflifiedListData, UpdateUserSelectedCsdData, resetErrorMessage, resetSaveSuccessStatus } from "../../redux/store/CsdDefinitionsStore";
import _, { filter } from "lodash";
import { createArrysOfIdsBySystemNames } from "../../helpers/stringMutations";

type CsdDefinitionListDataType = {
    csdDefinitionsList: SimplifiedCsdDefinitions[],
    onSaveSelection? : (onSave: boolean) => void
  }
  
const CsdDefinitionRow = ({csdDefinitionsList, onSaveSelection}: CsdDefinitionListDataType) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { toastQueue, addToast } = useToast();
    const { hasError, errorMessage, isSaveSuccess, isLoading, userSelectedCsdsDefinitionsList, csdsDefinitionsList } = useAppSelector<CsdDefinitionsStateType>(state => state.csdDefinitionsStore);
    const [selectSystemsOpen, setSelectSystemsOpen] = useState(false);
    const [filteredList, setFilteredList] = useState<SimplifiedCsdDefinitions[]>(csdDefinitionsList);
    const [filterString, setFilterString] = useState('');
    const [isUnsaved, setIsUnsaved] = useState(false);

    const onSelectSystems = () => {
        setSelectSystemsOpen(true);
    };
    
    const handleCancelButton = () => {
      let savedUserSetting =createArrysOfIdsBySystemNames(JSON.stringify(userSelectedCsdsDefinitionsList)) ;
      const csdDefinitionListData = _.chain(csdsDefinitionsList)
      .groupBy('systemName')
      .map((csdDefinition, systemName) =>
      ({
          systemName: systemName,
          isSystemChecked: !!savedUserSetting.find(({ systemName: sn }) => sn === systemName),
          messageIdList: csdDefinition.map(({ id, message }) =>
          ({
              id: id,
              isIdChecked: savedUserSetting.find(({ systemName: sn }) => sn === systemName)?.ids.includes(id) || false
          }))
      })).value();

      setFilterString('');
      setFilteredList(csdDefinitionListData);
      setSelectSystemsOpen(false);
  }
  
    useEffect(() => {
      setFilteredList(csdDefinitionsList);
    }, [csdDefinitionsList])

    useEffect(() => {
      if(hasError  && errorMessage !== '') {
        setIsUnsaved(true);
        addToast({
          title: t("TS_core:UnExpectedErrorTitle"), text: t("TS_core:UnExpectedErrorMessage"),
          type: "error"
        });
        setTimeout(() => {
          dispatch(resetErrorMessage());
        }, 5000);
      }
    }, [hasError, errorMessage]);

    useEffect(() => {
      if(isSaveSuccess) {
        setIsUnsaved(false);
        onSaveSelection?.(isSaveSuccess);
        dispatch(resetSaveSuccessStatus());
        setSelectSystemsOpen(false);
      }
    }, [isSaveSuccess]);
  
    const handleClearAllSelections = () => {
      const clearAllSystemscStatus = filteredList.map(system => ({
        ...system,
        isSystemChecked: false,
        messageIdList: system.messageIdList.map(item => ({
          ...item,
          isIdChecked: false
        }))
      }));
      updateUserSelectSImpflifiedList(clearAllSystemscStatus);
    };

    const handleCheckboxHeaderChange = (event: React.ChangeEvent<HTMLInputElement>, item: SimplifiedCsdDefinitions) => {
      const updatedList = updateHeaderCheckboxStatus(filteredList, item, event.target.checked);
      if(filterString !== ''){
          const updatedSystemsData = updateHeaderCheckboxStatus(csdDefinitionsList, item, event.target.checked);
          updateUserSelectSImpflifiedList(updatedSystemsData);
        }else{
          updateUserSelectSImpflifiedList(updatedList)
        }
    }

    const updateHeaderCheckboxStatus = (csdList: SimplifiedCsdDefinitions[], item: SimplifiedCsdDefinitions, status: boolean) => {
      const updateHeaderData = csdList.map((system) => 
      system.systemName === item.systemName ? { 
        ...system, 
        isSystemChecked: status,
        messageIdList: item.messageIdList.map((item) => ({
          ...item,
          isIdChecked: status,
        })),
      } : system)
      return updateHeaderData;
    }

    const handleCheckboxItemChange = (event: React.ChangeEvent<HTMLInputElement>, selectedMessageId: any, item: SimplifiedCsdDefinitions) => {
      const updatedList = updateChildCheckboxStatus(filteredList, selectedMessageId, item, event.target.checked);
        if(filterString !== ''){
          const updatedSystemsData = updateChildCheckboxStatus(csdDefinitionsList, selectedMessageId, item, event.target.checked);
          updateUserSelectSImpflifiedList(updatedSystemsData);
        }else{
          updateUserSelectSImpflifiedList(updatedList)
        }
        
     };

    const updateChildCheckboxStatus = (csdList: SimplifiedCsdDefinitions[], selectedMessageId: any, item: SimplifiedCsdDefinitions, status: boolean) => {
      const updateChildData =csdList.map((listItem) => {
        if (listItem === item) {
            const updatedMessageIdList = listItem.messageIdList.map((msgItem) => {
            if (msgItem.id === selectedMessageId) {
              return { ...msgItem, isIdChecked: status };
            }
            return msgItem;
          });
          return { ...listItem, messageIdList: updatedMessageIdList, isSystemChecked: updatedMessageIdList.some((messageItem) => messageItem.isIdChecked) };
        }
        return listItem;
      });
      return updateChildData;
    }

    const handleSystemWiseSelectAllButton = (systemName: string) => {
      const updatedSystemsData = updateSystemWiseCheckboxStatus(filteredList, systemName , true);
      if(filterString !== ''){
        const updatedSystemsData = updateSystemWiseCheckboxStatus(csdDefinitionsList, systemName, true);
        updateUserSelectSImpflifiedList(updatedSystemsData);
      }else{
        updateUserSelectSImpflifiedList(updatedSystemsData)
      }
    };
  
    const   handleSystemWiseClearAllButton = (systemName: string) => {
      const clearSystemsData = updateSystemWiseCheckboxStatus(filteredList, systemName , false);
      if(filterString !== ''){
        const updatedSystemsData = updateSystemWiseCheckboxStatus(csdDefinitionsList, systemName, false);
        updateUserSelectSImpflifiedList(updatedSystemsData);
      }else{
        updateUserSelectSImpflifiedList(clearSystemsData)
      }
    }; 

    const updateSystemWiseCheckboxStatus = (csdList: SimplifiedCsdDefinitions[], systemName: string, status: boolean) => {
      const updateChildData = csdList.map((system) => {
        if (system.systemName === systemName) {
          return {
            ...system,
            isSystemChecked: status,
            messageIdList: system.messageIdList.map((item) => ({
              ...item,
              isIdChecked: status,
            })),
          };
        }
        return system;
      });
      return updateChildData;
    }

    const updateUserSelectSImpflifiedList = (userSelections: SimplifiedCsdDefinitions[]) => {
        dispatch(UpdateSImpflifiedListData(userSelections));
        setIsUnsaved(true);
    }

    const handleSaveSelections = () => {
      dispatch(UpdateUserSelectedCsdData(filteredList));
    };

    return (
    <>
    <div>
        <div className='upper-bar-flex'>
            <button className="sdds-btn sdds-btn-primary sdds-btn-sm block"
                title={t("Edit System Overview")}
                onClick={onSelectSystems}>
                {t("Edit System Overview")}
            </button>
        </div>
        <CustomModal
            id="systems-modal"
            title={t("TS_temp:Select systems and CSDs to view on the overview page")}
            size="lg"
            handleClose={handleCancelButton}
            show={selectSystemsOpen}
            actions={
        <>
          <button
            type="button"
            className="sdds-btn sdds-btn-sm sdds-btn-primary bttn"
            onClick={handleSaveSelections}
            disabled={!isUnsaved}
          >
            {t("TS_core:SaveSelections")}
          </button>
          <button
            type="button"
            className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn"
           onClick={handleClearAllSelections}
          >
            Clear all selections
          </button>
          <button
            type="button"
            className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn"
            onClick={handleCancelButton}
          >
            {t("TS_core:Cancel")}
          </button>
        </>
      }
    > 
    {isLoading && <div className="spiner-section"><Spinner /></div>}
     <div>
          <p>
            <input
              type="text"
              className="sdds-textfield-input"
              placeholder={"Filter systems by name"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setFilterString(e.target.value)}
            />
          </p>
        </div>
    <>
    <div>
   
    {filteredList && filter(filteredList, (x) => {
      if (filterString !== '') {
        return x.systemName.toLowerCase().includes(filterString.toLowerCase())
      } else {
        return true;
      }
    }).map((item, index) => (
      <tds-accordion  key={index}>
        <tds-accordion-item 
          expand-icon-position='end'
          padding-reset={true} >
          <div slot="header" key={index} >
            <ul className="accordion-list"> 
              <li title={item.systemName} className="rc-accordion-title" key={item.systemName}  >
                <label>
                <input 
                    className="sdds-form-input" 
                    type="checkbox"
                    checked={item.isSystemChecked}
                    onChange={(e) => handleCheckboxHeaderChange(e, item)}  />
                  {item.systemName} 
              </label>
            </li>
            </ul> 
          </div>
          <div>
            <div className="tds-accordion-item-action">
              <button 
                type="button"
                className="sdds-btn sdds-btn-xs sdds-btn-primary bttn"
                onClick={() => handleSystemWiseSelectAllButton(item.systemName)} >
                {t("TS_temp:Select All")}
              </button>
              <button 
                type="button"
                className="sdds-btn sdds-btn-xs sdds-btn-secondary bttn"
                onClick={() => handleSystemWiseClearAllButton(item.systemName)}
                >
                  {t("TS_core:Clear")}
                </button> 
            </div>
            <div className="checkbox-container"> 
            <ul className="checkbox-list">
              {item.messageIdList.length > 0 && item.messageIdList.map((messageItem, idx) => (
                  <li  title={messageItem.id.toString()} key={messageItem.id}>
                    <label>
                      <input 
                            className="sdds-form-input"
                            type="checkbox"
                            checked={messageItem.isIdChecked}
                            onChange={(eve) => handleCheckboxItemChange(eve, messageItem.id, item)} 
                             />
                      {messageItem.id}
                    </label>
                  </li>
                
              ))} 
              </ul>
             
                </div>
        </div>
        </tds-accordion-item>
      </tds-accordion>
    ))}
    </div>
    <div>
        <Toast toastQueue={toastQueue} />
    </div>
    </>
    </CustomModal> 
    </div>
    </>
    )
};


export default CsdDefinitionRow;