function fullCircleSvg() {
  return '<svg ' +
    'width="14" height="14" ' +
    'xmlns="http://www.w3.org/2000/svg">' +
    '<circle r="5" cx="7" cy="7" stroke="#041e42" stroke-width="2" fill="#041e42" />' +
    '</svg>';
}

function emptyCircleSvg() {
  return '<svg ' +
    'width="14" height="14" ' +
    'xmlns="http://www.w3.org/2000/svg">' +
    '<circle r="5" cx="7" cy="7" stroke="#041e42" stroke-width="2" fill="#fafafa" />' +
    '</svg>';
}

function halfCircleSvg() {
  return '<svg ' +
    'width="14" height="14" ' +
    'xmlns="http://www.w3.org/2000/svg">' +
    '<circle r="5" cx="7" cy="7" stroke="#041e42" stroke-width="2" fill="#fafafa" />' +
    '<path d="M 7 12 A 1 1 0 0 0 7 2" fill="#041e42" />' +
    '</svg>';
}

function crossedCircleSvg() {
  return '<svg ' +
    'width="14" height="14" ' +
    'xmlns="http://www.w3.org/2000/svg">' +
    '<circle r="5" cx="7" cy="7" stroke="#041e42" stroke-width="2" fill="#fafafa" />' +
    '<line x1="3" y1="11" x2="11" y2="3" stroke="#041e42" stroke-width="2"/>' +
    '<line x1="3" y1="3" x2="11" y2="11" stroke="#041e42" stroke-width="2"/>' +
    '</svg>';
}

function generateClusterPosition(clusterSize: number) {
  var strokeWidth = 15,
    cSize = (clusterSize >= 1000) ? 72 : (clusterSize >= 100) ? 56 : (clusterSize >= 10) ? 40 : 32,
    diameter = strokeWidth * 2 + cSize,
    innerRadius = diameter / 2 - strokeWidth;
  return '<svg ' +
    'width="' + diameter + '" height="' + diameter + '" ' +
    'xmlns="http://www.w3.org/2000/svg">' +
    '<circle r="' + innerRadius + '" cx="' + diameter / 2 + '" cy="' + diameter / 2 + '" stroke="#041E42" stroke-width="' + strokeWidth + '" stroke-opacity="0.3" fill="#041E42" />' +
    '<text x="50%" y="50%" font-size="10pt" ' +
    'font-family="Arial" font-weight="bold" text-anchor="middle" dy=".4em" ' +
    'fill="#fafafa">' + clusterSize + '</text>' +
    '</svg>';
}

export { fullCircleSvg, emptyCircleSvg, halfCircleSvg, crossedCircleSvg, generateClusterPosition }