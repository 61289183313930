import React from 'react';

type ViewDefinitionRowProps = {
    id: number,
    systemName: string,
    showDetails: () => void;
    isLoading: boolean;
    selectedItem: number | null
}
const ViewDefinitionRow = (props: ViewDefinitionRowProps ) => {
    const {id, systemName, selectedItem, showDetails } = props;    

    return (
        <>
        <div className={`details-view-cell ${selectedItem === id ? 'selected' : ''}`} onClick={showDetails} onKeyDown={showDetails} role="button" >
                    <div className='name-column'>{systemName}</div> 
                    <div className='id-column'>{id}</div> 
        </div>
      </>
    )
}
export default ViewDefinitionRow;