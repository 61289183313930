import React from "react"
import { DateSearch } from "@cd/sdds-common-components-react";

interface SearchAndFilterPropTyps {
  onSearch: (fromDate: string, toDate: string) => void
}

const SearchAndFilter = ({ onSearch }: SearchAndFilterPropTyps) => {

  const onSearchClick = (fromDate: string, toDate: string ) => {
        onSearch(fromDate, toDate);
  }

  return <>
    <DateSearch 
      qsOptions={['Last30Mins', 'Last1Hour', 'Last4Hours', 'Last24Hours']}
      onSearchClick={onSearchClick}
      />
  </>
}

export default SearchAndFilter