import React, { useState } from "react";
import AccordionItem from "./Accordion/accordionItem";
import "./SystemOverview.scss";
import SystemDetails from "./SystemDetails";
import { useAppSelector } from "../../redux/store";
import { Spinner } from "@cd/sdds-common-components-react";

const SystemOverview = (props: CsdOverviewType) => {
  const { overviewCollection } = props
  const { systemName } = useAppSelector<CsdIdSelectionType>(state => state.CsdIdSelectionStore);
  const { isLoading } = useAppSelector<CsdOverviewStateType>(state => state.csdOverwiewStore);
  const [selectedAccordion, setSelectedAccordion] = useState(-1); 

  return (
    <>
      <div className="system-overview">
        {isLoading && <div className="tds-u-mt3"><Spinner size="md"/></div>}
        {overviewCollection.map((overviewItem, index) => {
          return (            
              <AccordionItem
                key={index}
                systemName={overviewItem.systemName}
                csdCount={overviewItem.csdCount}
                productEntities={overviewItem.productEntities}
                users={overviewItem.users}
                details={overviewItem.details}
                accordionIndex={index}
                selectedAccordion={selectedAccordion}
                setSelectedAccordion={setSelectedAccordion}
              />           
          );
        })}
      </div>
      {systemName && <SystemDetails />}
    </>
  );
};

export default SystemOverview;
