import React from "react";
import {
  DataTable,
  DataCell,
  DataRow,
  Spinner,
} from "@cd/sdds-common-components-react";

import { t } from "i18next";
import DateTimeSeperator from "../DateTimeSeperator";
import isGuid from "../../../../helpers/isGuid";
import RedirectSvg from "../../../assets/RedirectSvg";
import MoreArrow from "../../../assets/MoreArrow";

const RedirectUrl = `https://equipment${process.env.REACT_APP_CSTT_DOMAIN}/communication/`;

interface CsdDataTablePropType {
  tableData: Array<CsdImpactType>;
  title: string;
  isLoading: boolean;
  onSelectedImpacts: (csd: any) => void;
  activeRowIndex:Number; 
  setActiveRowIndex:Function;
}

interface ColumnConfigType {
  columnid: string;
  title: string;
  sortable: boolean;
  freeze?: boolean;
  colSpan?: number;
  removeable?: boolean;
}

export const EquipmentDataTable = ({ tableData, title, isLoading, onSelectedImpacts, activeRowIndex, setActiveRowIndex }: CsdDataTablePropType) => {

  const columnsConfig: Array<ColumnConfigType> = [
    {
      columnid: "data",
      title: t("Equipment"),
      sortable: false,
    },
    {
      columnid: "timestamps",
      title: t("Impact(s) at"),
      sortable: false,
    }
  ];
  const onRowClick = (equipment:CsdImpactType, index:number) =>{
    onSelectedImpacts(equipment)
    setActiveRowIndex(index);       
  };
  return (
    <div className="cd-table-wrapper">
      <DataTable title={title} columns={columnsConfig}>
        {tableData &&
          tableData.length > 0 &&
          tableData.map((row: CsdImpactType, index) => {
            return (
              <>
                <span
                  className={`${index === activeRowIndex ? 'selected-row':''} `}
                  role="button"
                  onKeyDown={() => { }}
                  onClick={() => {
                    onRowClick(row, index)             
                  }
                  }
                >
                  <DataRow>
                    {columnsConfig?.map((col) => {
                      switch (col.columnid) {
                        case "data":
                          return (
                            <>
                              {isGuid(row[col.columnid]) ?
                                <DataCell cssClassName="freeze">
                                  <a href={RedirectUrl + row[col.columnid]}><RedirectSvg/><span>{row[col.columnid]}</span></a>
                                </DataCell>
                                :
                                <DataCell cssClassName="freeze">
                                  {row[col.columnid]}
                                </DataCell>
                            }
                            </>
                          );
                        case "timestamps":
                          return (<>
                          {row.timestamps.length > 1 ?
                                <DataCell cssClassName="freeze">
                                  <span className="morearrow"><span><DateTimeSeperator inputDateTime={row[col.columnid][0]} /></span> <span><MoreArrow/></span></span>
                                </DataCell>
                                :
                                <DataCell cssClassName="freeze">
                                  <DateTimeSeperator inputDateTime={row[col.columnid][0]} />
                                </DataCell>
                            }
                          </>
                            
                          );
                        default:
                          return (
                            <DataCell cssClassName="freeze">
                              {"-"}
                            </DataCell>
                          );
                      }
                    })}
                  </DataRow>
                </span>
              </>
            );
          })}
        {(!tableData || tableData.length === 0) && isLoading && (
          <DataRow>
            <DataCell colSpan={3}>
              <Spinner size="md" />
            </DataCell>
          </DataRow>
        )}
        {(!tableData || tableData.length === 0) && !isLoading && (
          <DataRow>
            <DataCell colSpan={3}>{t("TS_core:NoDataFound")}</DataCell>
          </DataRow>
        )}
      </DataTable>
    </div>
  );
};

export default EquipmentDataTable;
