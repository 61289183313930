import React, { useContext, useEffect, useState } from "react";
import "./csd-details.scss";
import { Icons, TabsComponent } from "@cd/sdds-common-components-react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/store";
import DateTimeSeperator from "../DateTimeSeperator";
import { DocsFunctionTrigger } from '@cd/sdds-common-components-react'
import { UserContext } from "../../../../appContext";

interface CsdDetailsPropType {
  onClose: () => void;
  csd: CsdIdTimeType;
}

const CsdDetails = ({ onClose, csd }: CsdDetailsPropType) => {
  const { attributes } = useAppSelector<CsdAttributeStateType>(state => state.CsdAttributesStore);
  const { csdsDefinitionsList } = useAppSelector<CsdDefinitionsStateType>(state => state.csdDefinitionsStore);
  const { t } = useTranslation();
  const { settings } = useContext(UserContext);

  const [csdDefinition, setCsdDefinition] = useState<CsdDefinitionsResponseType | undefined>(undefined);

  useEffect(() => {
    var csdDefinition = getCsdDefinition(csd.systemName, csd.definitionId)
    setCsdDefinition(csdDefinition[0]);
  }, [csd]);

  function getCsdDefinition(systemName: string, csdId: string) {
    return csdsDefinitionsList.filter(element => {
      return element.systemName === systemName && element.id === Number(csdId);
    });
  }

  return (
    <div className="csd-details-wrapper">
      <div className="csd-details-header">
        <span className="sdds-headline-05">{t("Csd Details")}</span>
        <button onClick={onClose}>
          <Icons.CrossIcon />
        </button>
      </div>
      <TabsComponent
        titles={[`${t("TS_core:Instance")}`, `${t("TS_core:Definition")}`]}
      >
        {/* instance-panel */}
        <div className="instance-panel">
          <div className="white-box">
            <div>
              <span className="sdds-headline-06"> {t("TS_core:SystemName")} </span>
              <span className="sdds-u-mt1">{attributes.systemName}</span>
            </div>
            <div>
              <span className="sdds-headline-06">{t("TS_core:Id")}</span>
              <span className="sdds-u-mt1">{attributes.definitionId}</span>
            </div>
          </div>
          <div className="label sdds-headline-07 sdds-u-mt3">
            {t("TS_core:CreatedAt")}
          </div>
          <div>
            <DateTimeSeperator inputDateTime={csd.time} />
          </div>
          <div className="label sdds-headline-07 sdds-u-mt3">
            Log Entry ID
          </div>
          <div className="sdds-u-mt1 sdds-u-mb1">{attributes?.logEntryId}</div>

          <div className="label sdds-headline-07 sdds-u-mt3">
            Correlation IDs
          </div>
          <div className="sdds-u-mt1 sdds-u-mb1">
          { attributes.correlationIds.length > 0 ?
                attributes.correlationIds.map((corrId ) => {
                  return <>
                   <div>{ corrId }</div>
                   </>
              })  :  t("TS_core:None") }
          </div>

          <div className="label sdds-headline-07 sdds-u-mt3">
            {t("TS_core:ProductEntity")}
          </div>
          <div className="sdds-u-mt1 sdds-u-mb1">
             { attributes.productEntity.length > 0 ?
                attributes.productEntity.map((entity ) => {
                  return <>
                   <div> {entity.type + ': '} { entity.value }</div>
                   </>
              })  :  t("TS_core:None") }
          </div>

          <div className="label sdds-headline-07 sdds-u-mt3">
            {t("TS_core:User")}
          </div>
          <div className="sdds-u-mt1 sdds-u-mb1">
            { attributes.user.length > 0 ?
                attributes.user.map((usr ) => {
                  return <>
                   <div> { usr.value }</div>
                   </>
              })  :  t("TS_core:None") }
          </div>

          <div className="label sdds-headline-07 sdds-u-mt3">
            {"Fault value"}
          </div>
          <div className="sdds-u-mt1 sdds-u-mb1">{attributes.faultValue ? attributes.faultValue : t("TS_core:None") }</div>
        </div>

        {/* definition-panel */}
        <div className="definition-panel">
          <div className="white-box">
            <div>
            <span className="sdds-headline-06"> {t("TS_core:SystemName")} </span>
            <span className="sdds-u-mt1">{attributes.systemName}</span>
            </div>
            <div>
              <span className="sdds-headline-06">{t("TS_core:Id")}</span>
              <span className="sdds-u-mt1">{attributes.definitionId}</span>
            </div>
            <div>
              <span className="sdds-headline-06">{t("TS_core:Type")}</span>
              <span className="sdds-u-mt1">{csdDefinition?.type}</span>
            </div>
            <div>
              <span className="sdds-headline-06">{t("TS_core:Group")}</span>
              <span className="sdds-u-mt1">{csdDefinition?.group}</span>
            </div>
          </div>

          <div className="label sdds-headline-07 sdds-u-mt3">{t("TS_core:Title")}</div>
          <div className="sdds-u-mt1 sdds-u-mb1">{csdDefinition?.message.heading}</div>

          <div className="label sdds-headline-07 sdds-u-mt3">{t("TS_core:Deviation")}</div>
          <div className="sdds-u-mt1 sdds-u-mb1">{csdDefinition?.message.deviation}</div>

          <div className="label sdds-headline-07 sdds-u-mt3 red">{t("TS_core:Cause")}</div>
          <div className="sdds-u-mt1">{csdDefinition?.message.cause}</div>

          <div className="label sdds-headline-07 sdds-u-mt3">{t("TS_core:SystemReactio")}</div>
          <div className="sdds-u-mt1 sdds-u-mb1">{csdDefinition?.message.systemReaction}</div>

          <div className="label sdds-headline-07 sdds-u-mt3 green">{t("TS_core:Action")}</div>
          <div className="sdds-u-mt1 sdds-u-mb1">{csdDefinition?.message.action.text}</div>

          <div className="label sdds-headline-07 sdds-u-mt3">{t("TS_core:Url")}</div>
          <div className="sdds-u-mt1 sdds-u-mb1">{csdDefinition?.message.action?.url}</div>

          <div className="label sdds-headline-07 sdds-u-mt3">{t("TS_core:QuickFix")}</div>
          <div className="sdds-u-mt1 sdds-u-mb1">
            {/* TODO: Handle quick solution. See src\app\directives\task-runner-trigger\task-runner-trigger.ctrl.js */}
            {csdDefinition?.message.action.docsFunctionIds && csdDefinition.message.action.docsFunctionIds.map((functionId: string) => {
            return <DocsFunctionTrigger functionId={functionId} languageCode={settings.language} />
            })
            }
          </div>

          <div className="label sdds-headline-07 sdds-u-mt3">{t("TS_core:EngineeringComment")}</div>
          <div className="sdds-u-mt1 sdds-u-mb1">{csdDefinition?.message.engineeringComment}</div>

        </div>
      </TabsComponent>
    </div>
  );
};

export default CsdDetails;
