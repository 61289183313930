import { AxiosPromise } from 'axios';
import { csttFacadeApi } from '@cd/sdds-common-components-react';

const getCsdDefinitions = (): AxiosPromise<Array<CsdDefinitionsResponseType>> => csttFacadeApi.get('/csttfacade/docs/v1/csddefinitions');
const getCsdOverviewV2 = (requestPayload: CsdOverviewRequest): AxiosPromise<CsdOverviewResponseType> => csttFacadeApi.post('/csttfacade/docs/v1/csd/overviewV2', requestPayload)
const getCsdOverviewDetails = (requestPayload: OverviewDetailsType): AxiosPromise<OverviewDetailsResponseType> => csttFacadeApi.post(`/csttfacade/docs/v2/csd/overview/details/csd`, requestPayload)
const getCsdOverviewDetailsProductEntity = (requestPayload: OverviewDetailsType): AxiosPromise<OverviewDetailsResponseType> => csttFacadeApi.post(`/csttfacade/docs/v2/csd/overview/details/productentity`, requestPayload)
const getCsdOverviewDetailsUser = (requestPayload: OverviewDetailsType): AxiosPromise<OverviewDetailsResponseType> => csttFacadeApi.post(`/csttfacade/docs/v2/csd/overview/details/user`, requestPayload)
const getCsdWithAttributes = (csdId: string): AxiosPromise<Array<CsdAttributeStateType>> => csttFacadeApi.get(`/csttfacade/docs/v1/csd/${csdId}`);
const updateCsdDashboardSettings = (requestPayload: CsdUserSettings) => csttFacadeApi.put(`/csttfacade/user/v2/setting`, requestPayload);
const deleteCsdDefinition = (systemName: string, id: string): AxiosPromise<any> => csttFacadeApi.delete(`/csttfacade/docs/v1/csddefinition?id=${id}&systemname=${systemName}`)

export { getCsdDefinitions,
    getCsdOverviewV2,
    getCsdOverviewDetails,
    getCsdOverviewDetailsUser,
    getCsdOverviewDetailsProductEntity,
    getCsdWithAttributes,
    updateCsdDashboardSettings,
    deleteCsdDefinition }