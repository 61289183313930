import React, { useContext, useEffect, useState } from "react"
import SystemSubscription from "../../components/SystemSubscription"
import { UserContext } from "../../appContext";
import { clearStore, getSystemSubscriptions, resetHasError, resetSaveSuccess, saveSystemSubscriptions } from "../../redux/store/CsdSystemSubscriptionStore";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { cloneDeep, filter, find, isEqual } from "lodash";

const CsdSystemSubscriptionPage = () => {
  const { email } = useContext(UserContext);
  const { systemSubscriptions, isLoading, hasError, saveSuccess } = useAppSelector(state => state.csdSystemSubscription);
  const [unSavedSubscriptionsList, setUnSavedSubscriptionsList] = useState<Array<CsdSystemSubscription>>([]);
  const dispatch = useAppDispatch();
  const [selectionCount, setSelectionCount] = useState<number>(0);
  const [savedSubscriptionsCount, setSavedSubscriptionsCount] = useState<number>(0);
  const [hasUnsavedChanges, setHasUnSavedChanges] = useState<boolean>(false);
  const [systemErrorEmailIncorrect, setSystemErrorEmailIncorrect] = useState<boolean>(false);
  const [systemErrorEmailNotAllowed, setSystemErrorEmailNotAllowed] = useState<boolean>(false)


  useEffect(() => {
    if (email && _isCorrectEmailAddress(email) && _isAllowedEmailAddress(email)) {
      dispatch(clearStore())
      dispatch(getSystemSubscriptions())
    } else {
      setSystemErrorEmailIncorrect(true);
      setSystemErrorEmailNotAllowed(true);
    }
  }, [email]);

  useEffect(() => {
    setUnSavedSubscriptionsList(systemSubscriptions);
    checkHasUnsavedChanges(systemSubscriptions);
    const userSubscriptionCount = filter(systemSubscriptions, { isSubscribed: true });
    setSelectionCount(userSubscriptionCount?.length)
    setSavedSubscriptionsCount(userSubscriptionCount?.length)
  }, [systemSubscriptions])


  useEffect(() => {
    if (saveSuccess) {
      setTimeout(() => {
        dispatch(resetSaveSuccess());
      }, 5000);
    }
  }, [saveSuccess])

  useEffect(() => {
    if (hasError) {
      setTimeout(() => {
        dispatch(resetHasError());
      }, 5000);
    }
  }, [hasError])

  const onSubscriptionUpdate = (systemName: string, isSubscribed: boolean) => {
    let newlist = cloneDeep(unSavedSubscriptionsList)
    let system = find(newlist, { systemName: systemName });
    if (system) {
      system.isSubscribed = isSubscribed
    }
    if (isSubscribed) {
      setSelectionCount(selectionCount + 1);
    } else {
      setSelectionCount(selectionCount - 1)
    }
    setUnSavedSubscriptionsList(newlist);
    checkHasUnsavedChanges(newlist)
  }

  const onSave = () => {
    dispatch(saveSystemSubscriptions(unSavedSubscriptionsList))
  }

  const onClear = () => {
    const userSubscriptionCount = filter(systemSubscriptions, { isSubscribed: true });
    setSelectionCount(userSubscriptionCount?.length)
    setUnSavedSubscriptionsList(systemSubscriptions);
    checkHasUnsavedChanges(systemSubscriptions);
  }

  const checkHasUnsavedChanges = (unSavedSubscriptionsList: Array<CsdSystemSubscription>) => {
    const areEqual = isEqual(systemSubscriptions, unSavedSubscriptionsList)
    setHasUnSavedChanges(!areEqual);
  }

  const _isCorrectEmailAddress = (email: string) => {
    var regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return (regex.test(String(email).toLowerCase()));
  }

  const _isAllowedEmailAddress = (email: string) => {
    var regex = /[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(scania)\.[a-zA-Z]+$/;
    return (regex.test(String(email).toLowerCase()));
  }

  return <SystemSubscription
          emailAddress={email}
          systemSubscriptions={unSavedSubscriptionsList}
          isLoading={isLoading}
          hasError={hasError}
          currentSelectionCount={selectionCount}
          savedSubscriptionsCount={savedSubscriptionsCount}
          unSavedChanges={hasUnsavedChanges}
          systemErrorEmailIncorrect={systemErrorEmailIncorrect}
          systemErrorEmailNotAllowed={systemErrorEmailNotAllowed}
          onSubscriptionUpdate={onSubscriptionUpdate}
          saveSuccess={saveSuccess}
          onSave={onSave}
          onClear={onClear}
        />
}

export default CsdSystemSubscriptionPage;