import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import SearchAndFilter from "../../components/SearchAndFilter";
import SystemOverview from "../../components/SystemOverview";
import { createArrysOfIdsBySystemNames } from "../../helpers/stringMutations";
import { initialSearchDate } from "../../helpers/timeFormatter";
import { Icons, Toast, useToast } from "@cd/sdds-common-components-react";
import './dashboard.scss'
import { loadCsdDefinitionsOverview, clearCdsOverviewDataList, updateDashboardSelections, updateOnFirstLoad } from "../../redux/store/CsdOverviewStore";
import { loadCsdDefinitions } from "../../redux/store/CsdDefinitionsStore";
import CsdDefinitionRow from "../../components/CsdDefinitionRow";
import { setTime } from "../../redux/store/selectedDateStore";
import { setSelectedCsdId } from "../../redux/store/CsdIdSelectionStore";
import { UserContext } from "../../appContext";

type ProfileSettingsCdsDashBoard = {
  csddashboard?: string
};

const DashboardPage = ({ csddashboard }: ProfileSettingsCdsDashBoard) => {
  const { t } = useTranslation();
  const { toastQueue, addToast } = useToast();
  const dispatch = useAppDispatch();
  const { csdsOverviewList } = useAppSelector<CsdOverviewStateType>(state => state.csdOverwiewStore);
  const { onStoreLoad, selectedCsds } = useAppSelector<CsdOverviewStateType>(state => state.csdOverwiewStore);  
  const { simplifiedCsdDefinitionsList, userSelectedCsdsDefinitionsList } = useAppSelector<CsdDefinitionsStateType>(state => state.csdDefinitionsStore);
  const [isFirstViewLoad, setIsFirstViewLoad] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState<SearchInputType>(initialSearchDate());
  const { settings } = useContext(UserContext);

  useEffect(() => {
    //On first load of store, initialize csds with value from user settings
    if (onStoreLoad) {
        dispatch(updateDashboardSelections(createArrysOfIdsBySystemNames(settings?.csddashboard? settings?.csddashboard: "[]" ))); // will update selectedCsdsFromStore
        dispatch(updateOnFirstLoad(false));
    }
  }, []);

  useEffect(() => {
    if (onStoreLoad) return; //do not call any other apis on store's first load

    //load definitions for selected csds
    dispatch(loadCsdDefinitions(selectedCsds));
    //call overview api for each selected csd
    selectedCsds.forEach((s) => {
      dispatch(loadCsdDefinitionsOverview(searchInput.startTime, searchInput.endTime, "", s.systemName, s.ids));
    });
    if (selectedCsds.length === 0) dispatch(clearCdsOverviewDataList());
    setIsFirstViewLoad(false);
  }, [selectedCsds]);

 
  const handleOnSearch = (fromDate: string, toDate: string) => {
    //this method should be called only when the search button is hit, right now it is called automatically the first time the view is loaded
    if (isFirstViewLoad) return;

    setSearchInput({ startTime: fromDate, endTime: toDate });
    var selectedTime: SearchInputType = { startTime: fromDate, endTime: toDate }
    dispatch(setTime(selectedTime));
    dispatch(setSelectedCsdId('', 0)); //clear previous CSD selections 

    //call csd overview api with the selected time range for the selected csds    
    selectedCsds.forEach((s) => {
      dispatch(loadCsdDefinitionsOverview(fromDate, toDate, "", s.systemName, s.ids));
    });
  }

  const callLoadCsdDefinitionsOverview = () => {
    //converting back the selected csd definition list to selected csd list
    const groupedCsdDefinitionListData = _.chain(userSelectedCsdsDefinitionsList)
      .groupBy('systemName')
      .map((csdDefinition, systemName) =>
      ({
        systemName: systemName,
        messageIdList: csdDefinition.map((csd) =>
        (
          csd.id
        ))
      })
      ).value();

    var userselectdCsds: Array<UserSelectedCds> = [];
    groupedCsdDefinitionListData.forEach((n) => {
      userselectdCsds.push({ systemName: n.systemName, ids: n.messageIdList });
    });
    //update the new selections in store
    dispatch(updateDashboardSelections(userselectdCsds));
  }
 


  /* const onRefresh = () => {    
    selectedCsds && selectedCsds.forEach(e => {
      dispatch(loadCsdDefinitionsOverview(searchInput.startTime, searchInput.endTime, "", e.systemName, e.ids));
    });    
  } */

  //to show the toaster message on successfully saved CSD definitions in overview
  const onSaveSelection = (onSave: boolean) => {
    if (onSave) {
      addToast({
        title: t(""), text: t("System selections saved successfully."),
        type: "success",
      });
    }
    callLoadCsdDefinitionsOverview();
  }

  return <>
    <div className="dashboard">
      <div className="search-wrapper">
        <div className="sdds-col-xs-12 sdds-col-sm-12">
          <div className="sdds-row space-between">
            <div className="sdds-headline-05">{t("Search CSDs")} </div>
            <div className='flex'><Icons.InfoIcon />{t("TS_core:AllTimeIsUTCTime")}</div>
          </div>
          <div className="sdds-row">
            <SearchAndFilter onSearch={handleOnSearch} />
           {/* currently is duplicate functionality for search button, re-implement as in design if users request it
            <div className="refresh">
              {onRefresh && <button className="sdds-table__actionbar-btn" onClick={onRefresh} title={t("Fetch all CSDs between From time and now")}>
                <Icons.RefreshIcon />
              </button>}
            </div> */}
          </div>

        </div>
      </div>

      <div className="sdds-row sdds-u-p1 sdds-u-mt2">
        <div className="sdds-headline-05 sdds-u-pr2">{t("System Overview")}</div>
        <CsdDefinitionRow csdDefinitionsList={simplifiedCsdDefinitionsList} onSaveSelection={onSaveSelection} />
      </div>
      <div className="details-view">
        <SystemOverview overviewCollection={csdsOverviewList} />
      </div>
    </div>
    <div>
      <Toast toastQueue={toastQueue} />
    </div>
  </>
}

export default DashboardPage;
