import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';

const getInitialState = (): SearchInputType => {
    const State: SearchInputType = {
        startTime: moment.utc(Date.now()).subtract(24, 'hours').format("YYYY-MM-DD HH:mm:ss.SSS[Z]"),
        endTime: moment.utc(Date.now()).format("YYYY-MM-DD HH:mm:ss.SSS[Z]")
    }
    return State;
}

// Slice
const slice = createSlice({
    name: 'selectedDateStore',
    initialState: getInitialState(),
    reducers: {
        setStartTime: (state, action) => {
            state.startTime = action.payload;
        },
        setEndTime: (state, action) => 
        {
            state.endTime = action.payload;
        }
    },
});

export default slice.reducer

// Actions
const { setStartTime, setEndTime } = slice.actions

export const setTime = (date: SearchInputType) => async (dispatch: any) => {
    dispatch(setStartTime(date.startTime));
    dispatch(setEndTime(date.endTime));
}