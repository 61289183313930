import { ChangeEvent, useContext, useEffect, useState } from "react";

import CustomModal from "@cd/sdds-common-components-react/lib/esm/components/CustomModal";
import { createDocsUser } from "../../adapters/DocsAdapter";
import "./docsUser.scss"
import { Toast, useToast } from "@cd/sdds-common-components-react";
import { UserContext } from "../../appContext";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateDocsUserModal = ({ isOpen, onClose }: ModalProps) => {
    const { toastQueue, addToast } = useToast()
    const [teamInputValue, setTeamInputValue] = useState<string>('');
    const [departmentInputValue, setDepartmentInputValue] = useState<string>('');
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
    const loggedInProfileInfo = useContext<ProfileInformationType>(UserContext);

    // Handle input changes
    const handleTeamInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTeamInputValue(event.target.value);
    };
    const handleDepartmentInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDepartmentInputValue(event.target.value);
    };

    useEffect(() => {
        if (teamInputValue.length > 0 && departmentInputValue.length > 0)
            setSaveButtonDisabled(false);
        else
            setSaveButtonDisabled(true);
    }, [teamInputValue, departmentInputValue]);

    const handleSaveButtonClick = async () => {
        var newUser: DocsUserType = {
            name: loggedInProfileInfo.firstName + " " + loggedInProfileInfo.lastName,
            email: loggedInProfileInfo.email,
            team: teamInputValue,
            department: departmentInputValue
        };

        try {
            const result = await createDocsUser(newUser);
            if (result.data) {
                console.log('Creted user', result);
                addToast({
                    title: "DoCS user successfully created", text: "",
                    type: "success"
                });
            }
        } catch (e: any) {
            console.log('Failed to create user', e);
            addToast({
                title: "DoCS user creation failed", text: "Please try again or contact cstt support",
                type: "error"
            });
        }
        onClose();
        resetInputFields();
    };

    const handleModalClose = () => {
        onClose();
        resetInputFields();
    };

    const handleCancelClick = () => {
        onClose();
        resetInputFields();
    };

    const resetInputFields = () => {
        setTeamInputValue('');
        setDepartmentInputValue('');
    };

    return (
        <>
            <div className="sdds-modal-lg docs-user-modal">
                {isOpen && <CustomModal
                    id="createDocsUser-modal"
                    title="Create DoCS user"
                    size="sm"
                    handleClose={handleModalClose}
                    show={isOpen}
                    actions={
                        <>
                            <button
                                type="button"
                                className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn"
                                onClick={handleCancelClick}>
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="sdds-btn sdds-btn-sm sdds-btn-secondary bttn"
                                disabled={saveButtonDisabled}
                                onClick={handleSaveButtonClick}>
                                Save
                            </button>
                        </>
                    }>
                    <div>
                        <div className="add-func-modal-info-msg sdds-u-mb3">
                            <div className="info-msg">
                                <div className="description tds-body-02">Please enter and save the following details. Once you have saved it, you will receive an email with the credentials to use for registering CSDs.</div>
                                <div className="container">
                                    <span className="column">Email</span>
                                    <p>
                                        <input
                                            type="text"
                                            className="sdds-textfield-input"
                                            readOnly={true}
                                            value={loggedInProfileInfo.email}
                                        />
                                    </p>
                                </div>
                                <div className="container">
                                    <span className="column">Team*</span>
                                    <p>
                                        <input
                                            type="text"
                                            className="sdds-textfield-input"
                                            placeholder="For e.g. Core Diagnostics"
                                            onChange={handleTeamInputChange}
                                        />
                                    </p>
                                </div>
                                <div className="container">
                                    <span className="column">Department*</span>
                                    <p>
                                        <input
                                            type="text"
                                            className="sdds-textfield-input"
                                            placeholder="For e.g. ECP"
                                            onChange={handleDepartmentInputChange}
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal>}
                {<Toast toastQueue={toastQueue} />}
            </div>
        </>
    )
};

export default CreateDocsUserModal;